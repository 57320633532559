/* ==========================================================================
10. Bestellübersicht
========================================================================== */

figure#nurDerLadePlatzhalter {
    background: #ccc;
    max-width: 300px;
    text-align: center;
    color: #fff;
    padding: 20px 30px 20px 10px;
    border-radius: 50px;
    position: relative;
}

figure.button#nurDerLadePlatzhalter:after {
    content: "";
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 800ms infinite linear;
    display: block;
    position: absolute;
    top: 21px;
    right: 24px;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.zahlart span.box {
    font-weight: 900;
    text-transform: uppercase;
    color: $color-primary !important;
    line-height: 90%;
    font-size: 1.5rem;
}

.zahlart span.box a,
.zahlart span.box b {
    font-weight: initial !important;
    color: initial !important;
    font-size: initial !important;
    text-transform: initial !important;
}

.zahlart span.box b {
    margin-top: 20px;
    display: block;
    color: #999 !important;
}

.zahlart span.box a {
    color: $color-primary !important;
}

a.versandart span {
    color: $dunkeltext;
    margin: 0 10px 0px 10px;
    white-space: nowrap;
    font-size: 0.85rem;
    margin-left: auto;
}

table[style*="#DD2222"],
.PGerror,
.error_vor_absendenbutton {
    border: 1px solid $rot !important;
    padding: 18px;
    background: $rot;
    width: auto;
    margin: 6px auto;
}

table[style*="#DD2222"] *,
.PGerror *,
.error_vor_absendenbutton * {
    color: white;
    font-size: 16px;
    background: none;
}

img.achtung {
    height: 45px;
    margin-right: 20px;
}

.PGerror,
.error_vor_absendenbutton {
    padding-left: 60px;
    position: relative;
    display: block;
    color: white;
}

.PGerror img.achtung {
    margin: 0;
    position: absolute;
    left: 8px;
}

.mitte_login,
.mitte_login.abschluss {
    display: block !important;
}

.mitte_login.abschluss {
    .box {
        position: unset;
    }
}

.abschluss .order-info div.versandart span.box a {
    display: flex;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    padding: 5px;
    margin: 10px 20px 0 0;
    min-height: 38px;
}

#abs_ala u {
    display: none;
}

.abschluss .order-info div.versandart span.box a[data-aktiv="1"] {
    border: 2px solid;
}

.abschluss .order-info div.versandart span.box a[data-aktiv="1"]:before {
    background: $color-primary;
    box-shadow: inset 0 0 0 2px white;
}

.abschluss .order-info div.versandart span.box a:before,
.versandart span.box div.teil label:before {
    content: "";
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 16px;
    border: 1px solid #999;
    cursor: pointer;
    margin: auto 10px auto 10px;
    flex-shrink: 0;
}

.abschluss .order-info div.versandart span.box a:nth-of-type(1) {
    margin: 20px 20px 0 0;
}

.versandart img {
    max-width: 70px;
}

.mitte_login.abschluss table {
    border-spacing: 0;
    width: 100%;
}

.order-info div.col {
    background: $dark;
    padding: 20px 0px 20px 20px !important;
    display: flex;
    position: relative;
    flex: auto 1;
}

.order-info {
    margin: 0 0 20px 0 !important;
    align-items: normal;
}

.order-info div.col > div {
    flex: 1;
}

.order-info div.col:last-child div {
    border: none;
}

.order-info div.col a.edit {
    position: absolute;
    left: 20px;
    bottom: 20px;
    font-size: 1rem;
    background: url(#{$cdnPfad}/icon_edit.svg) 0 0 no-repeat;
    padding: 1px 0 5px 25px;
    background-size: 20px;
}

.order-info div.col .versandart {
    min-width: 260px;
}

.order-info div p {
    font-size: 1.4rem;
    color: $dunkeltext;
    margin: 0 0 10px 0;
}

.order-info span.box .teil {
    display: none;
}

.order-info span.box {
    margin: 0 0 40px 0;
    display: block;
    color: $helltext;
}

.order-info span.box a {
    color: $color-primary;
}

.mitVersandart,
.zk_versandartenTD {
    display: none;
}

.order-info form select {
    font-family: Catamaran, Arial, sans-serif !important;
    font-size: 0.7rem !important;
    margin: 10px 20px 0 0 !important;
    height: 45px;
    max-width: 243px;
}

.login_o {
    text-align: left;
}
