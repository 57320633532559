/*! http://responsiveslides.com v1.55 by @viljamis  */

.rslides_container {
    position: relative;
    float: left;
    width: 100%;

    &.instagram {
        background: #f9f9f9;
        margin-bottom: 50px;
    }
}

.rslides {
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
}

.rslides li {
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 0;
}

.rslides li:first-child {
    position: relative;
    display: block;
    float: left;
}

.rslides > img {
    display: block;
    height: auto;
    float: left;
    width: 100%;
    border: 0;
}

.rslides li div {
    min-height: 50px;
    width: 100%;
}

.transparent-btns_nav {
    z-index: 3;
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    display: block;
    background: #fff; /* Fix for IE6-9 */
    opacity: 0;
    filter: alpha(opacity = 1);
    width: 48%;
    text-indent: -9999px;
    overflow: hidden;
    height: 91%;
}

.transparent-btns_nav.next {
    left: auto;
    right: 0;
}

.centered-btns_tabs,
.transparent-btns_tabs,
.large-btns_tabs {
    padding: 0 0 10px 0;
    text-align: center;
    position: relative;
    z-index: 10;
}

.centered-btns_tabs li,
.transparent-btns_tabs li,
.large-btns_tabs li {
    display: inline;
    float: none;
    _float: left;
    *float: left;
    margin-right: 5px;
}

.centered-btns_tabs a,
.transparent-btns_tabs a,
.large-btns_tabs a {
    text-indent: -9999px;
    overflow: hidden;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background: #ccc;
    background: #00000033;
    display: inline-block;
    _display: block;
    *display: block;
    width: 8px;
    height: 8px;
    border: 2px solid #ffffff33;
}

.centered-btns_here a,
.transparent-btns_here a,
.large-btns_here a {
    background: #222;
    background: $color-primary;
}
