/* ==========================================================================
20. MEDIA-QUERIES - ALLGEMEIN
========================================================================== */

@media all and (min-width: 1690px) {
    .aug_menu li {
        flex-basis: 275px;
    }

    [role="navigation"] a {
        font-size: 30px;
    }

    #inWKsenden {
        font-size: 22px;
    }

    .wrapper_artikel-detail h1 {
        font-size: 37px;
    }

    .sonderpreis,
    #preisDiv {
        font-size: 42px;
    }
}

@media all and (max-width: 1690px) {
    header nav ul li {
        margin-right: 2vw;
    }
}

/* _lg----------------------------------------------------------------------------------------------------------------------------------- */
@media all and (max-width: 1280px) {
    .banner {
        margin: 0 auto;
    }

    .banner-text {
        font-size: 13px;
    }

    .banner-header {
        padding-top: 15px;
    }

    .BreadcrumbList span h1 {
        font-size: 2vw;
    }

    ul.men_FAQ_SUB li {
        flex: 0 0 25%;
        display: flex;
        align-items: center;
    }

    ul.men_Service,
    ul.men_FAQ_SUB {
        /*display: flex;
        flex-wrap: wrap;*/
    }

    .menu-right .login a span.dot:after,
    #shopLayer[data-online="1"] .menu-right .login a span.dot:after {
        display: none;
    }

    .order-info div.col:nth-of-type(3) div,
    .order-info div.col:nth-of-type(4) div {
        border-top: none;
    }

    .order-info div.col:nth-of-type(4) {
        padding-right: 20px !important;
    }

    .wrapper_artikel-detail figure.artikel_image {
        min-height: 560px;
    }

    span.rating_tab .rat_stars i,
    span.rating_tab .rat_stars b,
    span.rating_tab .rat_stars u {
        width: 15px;
        height: 15px;
    }

    header nav .submenu ul li.hover li a {
        margin: 0 10px 0 0;
        font-size: 1rem;
    }

    .BreadcrumbList span a {
        font-size: 2vw;
    }

    #shopLayer,
    .wrapper {
        max-width: 900px;
    }

    header .menu-right span.top_name {
        display: none;
    }

    header .menu-right {
        margin-right: 0;
    }
}

@media all and (min-width: 1281px) {
    .startseite .list_se .artikel:last-child {
        display: none;
    }
}

/* _md----------------------------------------------------------------------------------------------------------------------------------- */
@media all and (max-width: 1024px) {
    #logo > svg {
        height: 20px;
        width: 150px;
    }

    .footer-menu > div:last-child {
        margin: 40px 0 20px 0;
    }

    header nav ul li a {
        font-size: 1.1rem;
    }

    .aug_menu li {
        flex-basis: 235px;
    }

    .footer_1 > *:nth-of-type(2) {
        border: none;
    }

    .za_dk-div {
        width: 100%;
    }

    section.fortschritt {
        display: none;
    }

    .login_a,
    .login_b {
        width: 30vw;
    }

    .wk_anz_td tr {
        display: flex;
        flex-direction: column;
    }

    .wk .wk_name_td {
        flex-grow: 1.4;
    }

    table.wk .name_in_wk {
        font-size: 1rem;
    }

    td.wk_name_td .a3,
    td.wk_name_td .wk_best_1 {
        font-size: 0.8rem;
    }

    .wk .wk_preis_td {
        display: none;
    }

    .BreadcrumbList span h1 {
        font-size: 2.5vw;
    }

    span.location_aug2 + span + span.location_aug2 {
        height: 25px;
    }

    .BreadcrumbList {
        margin: 5px 0 0 0;
    }

    div#blaettern_oben {
        padding: 10px;
        background: rgba(0, 0, 0, 0.03);
    }

    .select_sortierung_se_top {
        top: 3px;
    }

    #bread {
        top: 16px;
    }

    .anzahl {
        top: 16px;
    }

    .top_bread-sort {
        flex-direction: column-reverse;
    }

    .footer.menu ul li {
        /*flex-basis: 50% !important;*/
    }

    .footer_1 span.footer_headline {
        font-size: 1.2rem;
    }

    .footer_1 > *:last-child {
        border: none;
    }

    .footer_1 > *:last-child {
        margin: 40px 0 0 0;
    }

    .footer_1 > div > div {
        min-width: 270px;
    }

    .footer_1 > * {
        border-right: 1px solid #ddd;
    }

    .box.groessentabelle {
        overflow-x: scroll;
    }

    ul.men_Service li,
    ul.men_FAQ_SUB li {
        flex: 1 0 33.33%;
    }

    .search {
        max-width: initial;
    }

    .order-info div.col:nth-of-type(2) div {
        border: none;
    }

    form[name="dd_aug_form"] > ul {
        flex: 1 0 calc(50% - 10px);
    }

    div#cs_layer {
        max-width: 750px;
    }

    .start_her a {
        max-width: 160px;
        margin: 15px;
    }

    .div_login table {
        min-width: 260px;
    }

    .wrapper_artikel-detail figure.artikel_image {
        min-height: 400px;
    }

    header .logo {
        max-height: 35px;
    }

    section.sub-submenu li.head {
        font-size: 1rem;
    }

    .sub-submenu ul li {
        padding: 0;
    }

    header nav .submenu ul li.hover li a {
        margin: 0 10px 0 0;
        font-size: 0.8rem;
    }

    span.location_zk1,
    span.location_aug,
    span.location_aug2,
    span.location_azu,
    span.location_sk2,
    span.location_sk1,
    span.location_zk2,
    span.location_her,
    span.breadcrum_arrow {
        background: url(#{$cdnPfad}/icon_breadcrum_arrow.svg) 0 50% no-repeat;
        background-size: contain;
        display: inline-block;
        width: 15px;
        height: 25px;
        margin: 0 10px;
        opacity: 0.3;
        color: #fff;
    }

    .BreadcrumbList span a {
        font-size: 2.5vw;
    }

    .search {
        margin: 10px 0 0 0;
        right: 0;
        position: relative;
        width: 100%;
        z-index: 1;
    }

    #shopLayer .search input {
        width: 100%;
        height: 45px !important;
        border-radius: 45px;
    }

    #shopLayer .search::after {
        width: 25px;
        height: 25px;
        top: 9px;
    }

    .submenu > ul > li {
        width: 137px;
        padding: 0;
        height: 45px;
    }

    header nav ul li {
        padding: 0;
    }

    section.sub-submenu,
    header nav ul li div .submenu {
        height: 340px;
    }

    header nav .submenu ul li a {
        font-size: 1em;
    }

    section.sub-submenu,
    .submenu > ul > li.hover:after {
        left: 160px;
    }

    header nav .submenu .sub-extra {
        width: 120px;
    }

    #shopLayer,
    .wrapper {
        max-width: 600px;
    }

    .search {
        position: relative;
        top: 0;
        height: 60px;
    }

    nav ul {
        justify-content: space-between;
    }
}

/* _sm---------------------------------------------------------------------------------------------------------------------------------- */
@media all and (max-width: 768px) {
    .mobile {
        display: block;
    }

    .artikel #aa_rating {
        width: 75px;
    }

    .artikel:hover > div {
        width: auto;
        min-height: 0;
        box-shadow: none;
        border: 0;
    }

    .grid-noGutter.bestell-artikel > div {
        font-size: 12px;
    }

    .banner-text {
        padding: 0 10px;
    }

    .se_farben > div {
        max-width: 144px;
        max-height: 18px;
        overflow: hidden;
    }

    .banner-text .show {
        display: block;
        color: $color-primary;
        cursor: pointer;
        text-align: center;
        padding-top: 10px;
    }

    .banner-text .panel {
        display: none;
    }

    .hutshop {
        float: none;
        width: 100%;
        margin-bottom: 10px;
    }

    .he_first,
    .alle_her {
        min-width: 110px;
        min-height: 110px;
    }

    ul.men_aug2.lvl_3 li.men__h.head,
    ul.men_aug2.lvl_3 li.men_aug2_h.head {
        padding: 5px 0 5px 10px;
    }

    #men_6,
    #men_10 {
        padding: 20px 15px;
    }

    li.men_head_eb_1_3 {
        order: 1;
    }

    #shopLayer header#shopHeader.fixed-header + main#shopMain {
        margin-top: 60px;
    }

    #shopLayer[data-pp*="aa"] header#shopHeader.fixed-header + main#shopMain,
    #shopLayer[data-pp*="htm"] header#shopHeader.fixed-header + main#shopMain {
        margin-top: 0;
    }

    .artikel:hover,
    .artikel.active {
        border: 2px solid #0000;
        border-left: none;
        border-right: none;
        transform: none;
        transform-origin: center;
        box-shadow: none;
        position: relative;
        z-index: 10;
    }

    .artikel:hover figure::after,
    .artikel.active figure::after {
        opacity: 0.1;
        transform: none;
    }

    .artikel:hover .azu {
        transform: none;
        opacity: 0;
    }

    .artikel:hover .artikel-img {
        transform: none;
    }

    .artikel:hover .se_fav {
        right: 10px;
        opacity: 0;
    }

    span.se_neu {
        font-size: 0.7rem;
    }

    .artikel #aa_rating {
        top: 10px;
    }

    .artikel #aa_rating .rat_stars i,
    .artikel #aa_rating .rat_stars b,
    .artikel #aa_rating .rat_stars u {
        width: 15px;
        height: 15px;
    }

    .artikel .se_preis {
        font-size: 1.2rem;
    }

    .startseite {
        display: flex;
        flex-direction: column;
    }

    .aug_menu {
        margin: 10px 0 0 0;
    }

    .startseite .mobile_jump_top {
        order: -1;
    }

    .mobile_jump_top h3 {
        display: none;
    }

    .menu-right .fav a span.dot:after {
        content: "";
        background: transparent;
        border: none;
    }

    .menu-right a {
        max-width: 25px;
    }

    .startseite > h3 {
        font-size: 1.5rem;
        margin: 10px 0;
    }

    .aug_menu li {
        flex-basis: auto;
        margin: 3px 0;
        display: flex;
        padding: 0 5px 0 5px;
        box-sizing: border-box;
    }

    .aug_menu li:nth-of-type(4),
    .aug_menu li:nth-of-type(5),
    .aug_menu li:nth-of-type(6) {
        display: none;
    }

    .aug_menu nav ul li a {
        font-size: 1.1rem;
        margin-right: 5px;
    }

    .aug_menu li a:before {
        width: 28px;
        height: 28px;
        margin: 0 5px 0 0;
    }

    .aug_menu nav ul {
        padding: 10px 5px;
        justify-content: space-between;
    }

    .wk_tab_summe a.wk_zk_button {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        padding: 15px 0;
    }

    #pp_express_link,
    div#LoginWithAmazon {
        width: 100% !important;
    }

    .wk_tab_summe a.wk_zk_button:after {
        width: 94%;
        border: none;
        color: #999;
    }

    .footer_1 .center_flex {
        justify-content: flex-start;
        margin: 0 0 20px 0;
    }

    .footer_1 span.footer_headline {
        text-align: left;
        margin: 0 0 10px 0;
    }

    .cross h2 {
        font-size: 0.9rem;
        font-weight: 700;
    }

    ul.cross_se {
        padding: 0;
    }

    ul.cross_se li.col-4_lg-4_md-6_sm-6_xs-12.artikel {
        flex-basis: 50% !important;
        max-width: 50% !important;
    }

    ul.cross_se li:nth-of-type(3),
    ul.cross_se li:nth-of-type(4),
    ul.cross_se li:nth-of-type(5),
    ul.cross_se li:nth-of-type(6) {
        display: none;
    }

    ul.cross_se .artikel .se_name {
        text-align: center;
        font-size: 0.8rem;
        margin: 4px 0;
        overflow: hidden;
        font-weight: 700;
    }

    ul.cross_se .artikel .se_name span + div {
        font-size: 0.8rem;
        display: inline-block;
        font-weight: 700;
    }

    .BreadcrumbList span h1 {
        font-size: 3vw;
    }

    #shopFooter .footer {
        padding-bottom: 0;
    }

    ul.men_Service li,
    ul.men_FAQ_SUB li {
        flex: 1 0 50%;
    }

    .wk_zk .zk_wk_ep {
        display: none;
    }

    span.wk_plus_minus {
        transform: translateX(-17%);
    }

    table#wk tr td form table:nth-of-type(2) tbody tr td table tbody tr td:first-child {
        order: 1;
        width: 93% !important;
        margin: 20px 0 0 20px;
    }

    table#wk tr td form table:nth-of-type(2) tbody tr td table tbody tr {
        flex-direction: column;
    }

    .wk_zk_div > table > tbody > tr:nth-of-type(3),
    .wk_zk_div > table > tbody > tr {
        width: 100% !important;
    }

    #Basket path#Path {
        transform: scale(1.2);
        transform-origin: 50% 50%;
    }

    #Basket text {
        transform: scale(1.07);
    }

    .wrapper.suche {
        display: flex;
    }

    #shopHeader .mobile_menu {
        font-size: 0.5rem;
        color: #777;
    }

    div#cs_header > span:nth-of-type(1) {
        display: none;
    }

    div#cs_layer {
        max-width: 650px;
        margin: 10px 10px;
        padding: 15px;
    }

    #cs_header h1 {
        font-size: 1.4rem;
    }

    #cs_header span {
        font-size: 1rem;
    }

    .search {
        margin: 0;
        display: none;
    }

    .div_login table {
        min-width: 250px;
    }

    section.fortschritt ul li a {
        font-size: 0.8rem;
    }

    .fortschritt ul li {
        height: 40px;
        line-height: 39px;
    }

    .BreadcrumbList span a {
        font-size: 3vw;
    }

    .col-12.nav {
        display: none;
    }

    .css_grid .artikel_info,
    .artikel_more {
        padding: 0 10px;
    }

    [role="navigation"] a {
        font-size: 3.8vw;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .wrapper_artikel-detail figure.artikel_image {
        min-height: 350px;
    }

    .wrapper_artikel-detail h1 {
        font-size: 5.2vw;
    }

    .sonderpreis,
    #preisDiv {
        font-size: 5.7vw;
    }

    .benefits .col {
        margin: 0 0 30px 0;
    }

    .mobile_menu,
    .mobile_search {
        display: block;
    }

    .mobile_search img {
        max-width: 25px;
    }

    header .top-left {
        display: none;
    }

    header .logo {
        max-width: 120px;
        margin: 17px auto;
    }

    #inWKsenden {
        font-size: 4.5vw;
    }
}

@media all and (max-width: 736px) {
    .artikel img {
        min-width: 0;
    }

    .fortschritt ul li {
        padding: 0 5px;
    }
}

@media all and (max-width: 648px) {
    header#shopHeader .wrapper {
        margin: 0 5px;
    }

    #men_6 a {
        font-size: 1rem;
        margin: 10px 10px 0 0;
        width: 100%;
        line-height: 110%;
    }

    .shop_tab_mitte,
    ul.sucheGross,
    #suchKruemel {
        padding: 0 5px;
    }

    [data-pp*="wk"] .shop_tab_mitte {
        padding: 0 8px;
    }
}

@media all and (max-width: 680px) {
    .artikel .se_farben {
        opacity: 1;
        background: none;
    }

    .artikel .se_farben .lief_gr_farbe1_k {
        opacity: 1;
        transform: translateY(0);
    }

    .artikel .se_farben .lief_gr_farbe1_k {
        width: 12px;
        height: 12px;
        margin: 2px;
    }

    #cs_layer .wk_img {
        display: none;
    }

    #cs_layer a.button {
        font-size: 1.1rem;
        font-weight: 900;
        padding: 10px 0;
    }

    footer#shopFooter {
        padding: 15px;
    }

    .footer_1 > * {
        border: none;
    }

    .tab_se_unten table td {
        padding: 0 15px;
        columns: unset;
    }

    /* header div.top-left::after {
  content:'';
  width:25px;
  height:24px;
  background: url(#{$cdnPfad}/'../img/hamburger.svg') 0 0 no-repeat;
}*/
}

/* _xs----------------------------------------------------------------------------------------------------------------------------------- */

@media all and (max-width: 576px) {
    .menu-right .col-4 {
        padding-right: 0px;
    }

    .bestell_img {
        margin: 0;
    }

    .bestell_img:before {
        height: 100px;
        top: -30px;
    }

    .bestell-artikel img.wk_pic {
        max-width: 35px;
    }

    .wk_zk_div .wk_zahlart_versand,
    .wk_zk_div .wk_versand {
        font-size: 9px;
    }

    .grid-noGutter.bestell-artikel > div {
        font-size: 10px;
    }

    .footer_1 > *:last-child {
        margin: 0;
    }

    span.rating_tab .rat_stars i,
    span.rating_tab .rat_stars b,
    span.rating_tab .rat_stars u {
        width: 12px;
        height: 12px;
        margin: 8px 0 0 0;
    }

    .rat_stars i,
    .rat_stars b,
    #aa_rating .rat_stars u {
        width: 18px;
        height: 18px;
    }

    .wk_ges_preis_td span span {
        font-size: 1rem;
    }

    td.wk_pic_td {
        display: none;
    }

    label input[type="checkbox"]:checked::before,
    label input[type="checkbox"]:not(:checked)::before,
    label input[type="radio"]:checked::before,
    label input[type="radio"]:not(:checked)::before {
        top: 3px;
    }

    .BreadcrumbList span h1 {
        font-size: 5vw;
    }

    header .menu-right svg {
        max-width: 35px;
    }

    #cs_layer a.button {
        font-size: 0.9rem;
        font-weight: 400;
    }

    ul.cross_se {
        max-height: 330px;
    }

    .login_o {
    }

    .login_a,
    .login_b {
        width: 95vw;
    }

    .div_login table {
        min-width: 90vw;
    }

    #inWKsenden {
        font-size: 6vw;
    }

    .BreadcrumbList span a {
        font-size: 5vw;
    }

    .se_banner.vorteil:before {
        display: none;
    }

    .artikel figure {
        min-height: 0;
    }

    .transparent-btns_tabs {
        height: 40px;
    }
}

@media all and (max-width: 510px) {
    form[name="dd_aug_form"] > ul {
        flex: 1 0 calc(100% - 10px);
    }

    nav ul li a {
        font-size: 1.1rem;
    }
}

@media all and (max-width: 350px) {
    nav ul li a {
        font-size: 0.8rem;
    }

    header div.top-left::after {
        display: none;
    }

    span.breadcrum_arrow {
        margin: 0 3px;
    }

    .anzahl {
        display: none;
    }

    /*???fehler beim iphone 5...
      header .top-right {
        margin-right: 10px;
        flex-basis: 43.33333% !important;
        max-width: 43.33333% !important;
      }
    */
}

@media all and (max-width: 631px) {
    div#shopLayer {
        overflow-x: hidden;
    }
}
