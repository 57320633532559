@import "helpers/mixins";

// base
@import "base/animate";
@import "base/browser";
@import "base/font";
@import "base/gridlex";
@import "base/magnific-popup";

// components
@import "components/artikeldetail";
@import "components/banner";
@import "components/checkout";
@import "components/favoriten";
@import "components/bestelluebersicht";
@import "components/artikel-bewerten";
@import "components/artikel-suchergebnis";
@import "components/breadcrumb-sortierung";
@import "components/filter";
@import "components/footer";
@import "components/grid";
@import "components/header";
@import "components/login";
@import "components/mobile-menu";
@import "components/normalize";
@import "components/powersuche";
@import "components/responsiveslides";
@import "components/suche";
@import "components/umfrage-bestellabschluss";
@import "components/warenkorb";
@import "components/ticketsystem";
@import "components/cookie_v3";

// pages
@import "pages/fehler";
@import "pages/service";
@import "pages/startseite";

@import "base/responsive";

/* ==========================================================================
    SONSTIGES
========================================================================== */

// @if $local_gulp == "1" {
//     //wenn in der .env LOCAL_GULP = 1 ist, dann wird hiermit der rote Punkt für den Redirector angezeigt

//     body:before {
//         content: "R";
//         position: fixed;
//         z-index: 100000000000000;
//         background: rgba(255, 82, 82, 1);
//         box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
//         animation: pulse-red 2s infinite;
//         border-radius: 1000px;
//         width: 25px;
//         height: 25px;
//         color: white;
//         text-align: center;
//         line-height: 25px;
//         margin: 5px;
//         font-size: 22px;
//     }

//     //Animation von https://www.florin-pop.com/blog/2019/03/css-pulse-effect/
//     @keyframes pulse-red {
//         0% {
//             transform: scale(0.95);
//             box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
//         }

//         70% {
//             transform: scale(1);
//             box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
//         }

//         100% {
//             transform: scale(0.95);
//             box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
//         }
//     }
// }

.gutschein .box {
    background: $dark;
    padding: 20px;
    margin: 20px 0 0 0;
    box-sizing: border-box;
    border-top: 2px solid $color-primary;
    border-bottom: 2px solid $color-primary;
}

#klarna_ala_warnung,
div#klarna_rech_info_neu {
    display: none;
}

span.error_vor_absendenbutton {
    padding: 20px 30px;
    border: 2px solid $rot;
    display: block;
    width: fit-content;
    margin: 20px 0 0 0;
    max-width: 520px;
    border-radius: 40px;
    background: #fff;
    color: $rot;
}

[data-pp*="agb"] .tab_k {
    display: none;
}

article.banner_oben,
article.banner_unten {
    columns: 320px 2;
}

article.banner_oben h2,
article.banner_unten h2 {
    column-span: all;
}

.inhaltsver h1 {
    margin: 30px 0 15px 0;
}

.inhaltsver h2 {
    margin: 20px 0 5px 0;
    border-bottom: 1px solid #ddd;
}

#shopLayer #stellen h3 {
    font-size: 1.2rem;
    padding: 30px;
}

#stellen section > div {
    margin-bottom: 30px;
    background: #f7f7f7;
    padding: 0 20px 20px 20px;
    width: 100%;
}

section.col ul {
    padding: 0 0 20px 20px;
}

#stellen section.col {
    display: flex;
}

.inhaltsver ul li:hover {
    cursor: pointer;
    background: $color-primary;
}

#stellen li:first-child {
    list-style-type: none;
    padding: 0 0 10px;
    font-weight: 600;
    color: #999;
}

#stellen h2 {
    color: #fff;
    background: $color-primary;
    padding: 10px;
    margin: 0 0 10px 0;
}

#shopLayer #stellen h3 {
    font-size: 1.2rem;
    padding: 0 30px;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#stellen .active h3 {
    font-weight: 300;
}

#stellen .active h3 {
    margin-bottom: 10px;
}

#stellen h3:before {
    content: "";
}

.inhaltsver {
    width: 100%;
}

.inhaltsver ul {
    margin-left: -6px;
}

.inhaltsver ul li {
    font-size: 13px;
    list-style: none;
    line-height: 130%;
    padding: 6px;
}

.inhaltsver ul li a {
    display: block;
}

.inhaltsver div {
    margin-bottom: 20px;
}

.inhaltsver .left,
.inhaltsver .right {
    display: inline;
    width: 450px;
    float: left;
}

.inhaltsver .left {
    margin-right: 70px;
}

.inhaltsver ul li:hover a {
    color: #fff;
}

#FooterJumperBanner {
    margin-bottom: 40px;
}

.tab_se_unten table td,
#FooterJumperBanner {
    text-align: left;
    columns: 2;
}

.tab_se_unten p,
#FooterJumperBanner p {
    font-size: 0.9rem;
    font-weight: 100;
    color: $helltext;
}

.tab_se_unten h3,
#FooterJumperBanner h3,
.tab_se_unten h4,
#FooterJumperBanner h4 {
    font-weight: 400;
    margin: 0 0 10px 0;
    font-size: 1.3rem;
    color: #999;
}

.tab_se_unten h3,
#FooterJumperBanner h3 {
    column-span: all;
}

.tab_se_unten h4,
#FooterJumperBanner h4 {
    font-size: 1.1rem;
}

.tab_se_unten h2,
#FooterJumperBanner h4 {
    font-size: 1.3rem;
}

.tab_se_unten h1,
#FooterJumperBanner h4 {
    font-size: 1.3rem;
}

/* Amazon Pay */

.amazonButton {
    background: #787878;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    width: 50%;
    margin: 20px auto;
    text-align: center;
    font-size: 20px;
}

#addressBookWidgetDiv {
    min-width: 300px;
    width: 100%;
    min-height: 228px;
    height: 240px;
    max-height: 400px;
}

#walletWidgetDiv {
    min-width: 300px;
    width: 100%;
    min-height: 228px;
    height: 240px;
    max-height: 400px;
}

#LoginWithAmazon > img {
    width: 200px;
}

/* Instagram quote */

.quote {
    padding: 5% 5% 3% 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    img {
        border-radius: 100%;
        max-width: 150px;
        max-height: 150px;
        margin: 0 60px 0 0;
        box-shadow:
            0 0 0 3px #f9f9f9,
            0 0 0 5px $helltext;
    }

    a {
        text-align: right;
        margin: 10px 0 0 0;
    }

    blockquote {
        padding: 0 30px;
        display: flex;
        align-items: center;
        position: relative;

        q {
            font-size: 1.3rem;
            color: $dunkeltext;
            position: relative;

            &:before,
            &::after {
                color: $color-primary;
                font-size: 3rem;
                line-height: 0;
            }

            &:before {
                position: absolute;
                left: -20px;
                top: 7px;
            }

            &:after {
                position: absolute;
                bottom: 7px;
            }
        }
    }
}

.instagram_icon {
    margin: 0 0 10px 10px;
    position: absolute;
    bottom: 0;
}

.video-wrapper {
    padding: 10px;

    .responsive-video {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
    }

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.vertical-center {
    align-self: center;
}

.se > li {
    list-style-type: none;
}

.banner-1x1 {
    object-fit: cover;
    width: 100%;
    height: 364px;
}

.horizontal-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@for $i from -1 through 48 {
    .o#{$i} {
        order: #{$i};
    }
}

@media all and (max-width: 1024px) {
    .quote {
        img {
            max-width: 80px;
            max-height: 80px;
        }

        blockquote q {
            font-size: 1.1rem;
        }
    }

    @for $i from -1 through 10 {
        .order-#{$i} {
            order: #{$i};
        }
    }
}

@media all and (max-width: 768px) {
    .quote {
        padding: 5% 3% 3% 0%;

        blockquote {
            padding: 0 10px;

            q {
                font-size: 1rem;

                &:before {
                    left: -13px;
                }

                &:before,
                &::after {
                    font-size: 2rem;
                }
            }
        }

        img {
            margin: 0 40px 0 0;
        }
    }
}

@media all and (max-width: 576px) {
    .banner-1x1 {
        height: 274px;
    }
}

.lierys-test {
    color: red;
}

#localTestingOn {
    position: fixed;
    width: 20px;
    height: 20px;
    display: block;
    background: $color-primary;
    z-index: 1000000000000000000000;
    top: 5px;
    left: 5px;
}

#bestellabschluss div.box {
    background: #f9f9f9;
    padding: 30px;
    box-sizing: border-box;
}

.white-popup {
    position: relative;
    background: #fff;
    padding: 20px;
    width: auto;
    max-width: 700px;
    margin: 20px auto;
    border-radius: 5px;
}

$base-font-size: 5px;

$switch-width: 9em;
$switch-size: 4.6em;
$switch-space: 0.2em;

$switch-off-padding: 0em;
$switch-on-padding: $switch-size - $switch-space;
$switch-button-size: $switch-size - $switch-space * 2;

$switch-off-border: #eee;
$switch-on-color: $color-primary;
$switch-off-color: #eee;
$switch-toggle-color: #fff;

$switch-animation-bounce-distance: 0.4em;

input.switch {
    font-size: $base-font-size;
    position: relative;
    display: inline-block;
    visibility: visible !important;
    appearance: none;
    width: $switch-width;
    height: $switch-size;
    line-height: $switch-size;
    border-radius: $switch-size / 2;
    margin: 0;
    padding: 0;
    box-shadow: inset 0 0 0 $switch-space $switch-off-border;
    outline: 1px solid transparent !important;
    cursor: pointer;
    border: none;
    background: $switch-off-color !important;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    transition:
        box-shadow 0.3s ease-in-out,
        padding 0.25s ease-in-out;
    transition-delay: 0.1s, 0s;

    &:checked {
        transition-delay: 0s, 0s;
        box-shadow: inset 0 0 0 ($switch-size / 1.75) $switch-on-color !important;
        padding-left: $switch-on-padding;
    }

    /* Animations if supported */
    &.hit {
        animation: slide-off 0.35s ease both;
    }

    &.hit:checked {
        animation: slide-on 0.35s ease both 0.05s;
    }

    &::before,
    &::after {
        content: "";
    }

    &::after {
        /* Increases hit area */
        $size-adjust: -($switch-button-size / 3);

        position: absolute;
        top: $size-adjust;
        left: $size-adjust;
        bottom: $size-adjust;
        right: $size-adjust;
    }

    &::before {
        display: inline-block;
        height: $switch-button-size;
        width: $switch-button-size;
        margin: $switch-space 0 0 $switch-space;
        background-color: $switch-toggle-color;
        border-radius: $switch-button-size / 2;
        box-shadow:
            0 $switch-space ($switch-space * 2) 0 rgba(0, 0, 0, 0.2),
            0 0 ($switch-space / 2) ($switch-space / 2) rgba(0, 0, 0, 0.1);
        transition: all 0.1s ease 0.1s;
    }

    &:active::before {
        transition: all 0.1s ease 0.05s;
    }

    &.touch:hover::before {
        transition: all 0s ease 0s;
    }

    &:active::before,
    &.touch:hover::before {
        width: $switch-button-size + $switch-space * 4;
    }

    &:active:checked::before,
    &.touch:hover:checked::before {
        margin-left: -($switch-space * 3);
    }
}

@keyframes slide-on {
    0% {
        padding-left: $switch-off-padding;
    }

    60% {
        padding-left: $switch-on-padding + $switch-animation-bounce-distance;
    }

    100% {
        padding-left: $switch-on-padding;
    }
}

@keyframes slide-off {
    0% {
        padding-left: $switch-on-padding;
        text-indent: 0;
    }

    60% {
        padding-left: $switch-off-padding;
        text-indent: -$switch-animation-bounce-distance;
    }

    100% {
        padding-left: $switch-off-padding;
        text-indent: 0;
    }
}
