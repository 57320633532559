$cdnPfad: "https://cdn.lierys.com";
$fontCdn: "https://css.lierys.com/f.php?f=";
// Farben
$buttonborder: #bdbdbd; // buttonborder1
$color-primary: #61a395; // farbe1
$color-secondary: #aaaeb1;

$hellgrau: #dddddd; // farbe4
$rot: #d84d4d; // farbe6
$dark: rgba(0, 0, 0, 0.03); // rgba1
$dunkeltext: #4a4a4a; // textfarbe1
$helltext: #9b9b9b; // textfarbe2

$backgroundgrau: rgba(0, 0, 0, 0.03);
