$abschicken: 'Abschicken';
$adresse: 'Adresse';
$adresse_aendern: 'Adresse ändern';
$aendern: 'Ändern';
$agb: 'AGB';
$agb_url: 'AGB.html';
$alles_auswaehlen: 'Alles auswählen';
$alle_preisangaben_inkl_mwst: 'Alle Preisangaben inkl. MwSt.';
$anderer_anlass: 'Anderer Anlass ...';
$andere_sportart: 'Andere Sportart ...';
$anlass: 'Anlass';
$anmelden: 'Anmelden';
$anmeldung: 'Anmeldung';
$anzahl: 'Anzahl';
$anzahl_der_artikel: 'Anzahl der Artikel:';
$artikel: 'Artikel';
$artikel_kombination_derzeit: 'Artikel-Kombination derzeit nicht lieferbar';
$artikel_nicht_verfuegbar: 'Artikel zur Zeit nicht verfügbar';
$artikel_zz_nicht_lieferbar: 'Artikel derzeit nicht lieferbar.';
$art_nr: 'Art-Nr:';
$ausloggen: 'Ausloggen';
$ausloggen_link: '/s01.php?shopid=s01&cur=eur&sp=de&ag=1&pp=login&lg=8';
$aus_derselben_serie: 'Aus derselben Serie';
$aus_welchem_hauptgrund_haben: 'Aus welchem Hauptgrund haben Sie die bestellten Artikel gewählt? *';
$benefit_lieferzeit: '1 - 3 Tage            <br>Lieferzeit';
$benefit_rueckgabe: '100 Tage            <br>Rückgaberecht';
$benefit_versand: 'Gratis Versand &            <br>Rückversand';
$beratung_bestellung: 'Beratung & Bestellung';
$bestaetigen: 'Bestätigen';
$bestaetigungs_email: 'Sie erhalten in Kürze eine Bestellbestätigung per E-Mail.';
$bestellbestaetigung: 'Bestellbestätigung';
$bestellnummer: 'Bestellnummer:';
$bestelluebersicht: 'Bestellübersicht';
$bestelluebersicht_link: '/s01.php?cur=eur&sp=de&ag=1&pp=bue';
$bestellungen_einsehen: 'Sie können Ihre Bestellungen auch jeder Zeit in Ihrem Konto einsehen:';
$bestellung_vom: 'Bestellung vom';
$bewertung: 'Bewertung';
$bic: 'BIC:';
$bitte_geben_sie_mindestens_ein: 'Bitte geben Sie mindestens einen Grund an.';
$bitte_w_hlen_sie_eine_gr_e_far: 'Bitte wählen Sie eine Größe/Farbe aus.';
$bleiben_sie_auf_dem_laufenden: 'Bleiben Sie auf dem Laufenden mit einem Konto bei uns:';
$breadcrumb_start: 'Start';
$caps: 'Caps';
$datenschutz: 'Datenschutz';
$datenschutz_1: 'Lierys.com nutzt Cookies und andere Technologien, damit unser Onlineshop zuverlässig und sicher läuft und wir Ihnen relevante Inhalte und personalisierte Werbung zeigen können. Damit das funktioniert, sammeln wir Daten über unsere Nutzer und darüber, wie sie unsere Angebote auf welchen Geräten nutzen.';
$datenschutz_2: 'Wenn Sie Bestätigen klicken, sind Sie damit einverstanden und erlauben uns, diese Daten an Dritte weiterzugeben, etwa an unsere Marketingpartner.  zustimmen</a>, beschränken wir uns auf die wesentlichen Cookies. In diesem Fall sind unsere Inhalte leider nicht auf Sie zugeschnitten. Weitere Details und alle Optionen finden Sie in den Einstellungen. Sie können diese auch später jederzeit anpassen. Beachten Sie zudem die Informationen in unserer <a href=\"Datenschutz.html\">Datenschutzerklärung</a>.';
$datenschutz_3: 'Hier können Sie das Tracking und die Analyse-Tools auf unseren Websites zulassen oder ausschließen.';
$datenschutz_4: 'Diese Daten verwenden wir, um die Performance unserer Webseiten und Services stetig zu							verbessern und um geschäftsrelevante Analysen zum Shopping-Erlebnis mit unseren Apps und							Webseiten durchzuführen.';
$datenschutz_5: 'Marketing-Cookies werden verwendet, um Ihnen relevante gesponserte Inhalte zu unseren							Produkten zu zeigen. Um für Sie ansprechende Inhalte auszuwählen, nutzen wir etwa							Informationen über vorherige Einkäufe und Interaktionen mit unserer Seite. Diese							Informationen und eine Kundenkennung, etwa eine verschlüsselte E-Mail-Adresse oder							Gerätedaten, geben wir gegebenenfalls an Dritte wie Facebook und Google weiter, um Ihnen auf							den Websites und in den Apps von Drittanbietern relevante Werbung zeigen zu können.							Womöglich verknüpfen wir diese Daten auch geräteübergreifend und verarbeiten Daten über die							gezeigte Werbung (z. B. ob Sie eine Anzeige angeklickt und anschließend etwas gekauft							haben), um die Effizienz unserer Anzeigen zu messen.';
$datenschutz_6: 'Die durch diese Services gesammelten Daten werden benötigt, um die technische Performance							unserer Website zu gewährleisten, grundlegende Einkaufsfunktionen bereitzustellen, das							Einkaufen bei uns sicher zu machen und um Betrug zu verhindern. Diese Cookies können nicht							deaktiviert werden, da unsere Website ohne sie nicht richtig funktionieren kann.';
$datenschutz_einstellungen: 'Datenschutz-Einstellungen';
$datenschutz_headline: 'Ihr Datenschutz ist uns wichtig';
$datenschutz_url: 'Datenschutz.html';
$derzeit_nicht_lieferbar: 'derzeit nicht lieferbar';
$details_bestellung: 'Details zu Ihrer Bestellung';
$die_naechsten_bewertungen: 'Die nächsten Bewertungen anzeigen';
$eine_kurze_frage_h_tten_wir_no: 'Eine kurze Frage hätten wir noch:';
$eingrenzen: 'EINGRENZEN';
$einstellungen: 'Einstellungen';
$einstellungen_bestaetigen: 'Einstellungen bestätigen';
$einzelpreis: 'Einzelpreis: ';
$email: 'E-Mail:';
$email_adresse: 'E-Mail-Adresse';
$fahrrad: 'Fahrrad';
$farbe: 'Farbe';
$fashion: 'Fashion';
$geburtstag_hochzeit: 'Geburtstag, Hochzeit ...';
$gesamt: 'Gesamt';
$gesamtsumme: 'Gesamtsumme: ';
$gesamtsumme_inkl_mwst: 'Gesamtsumme (inkl. 19% Mwst.)';
$geschenk: 'Geschenk';
$gratis_versand: '<strong>Gratis</strong> Versand<br>& Rückversand';
$groesse: 'Größe:';
$groessentabelle: 'Größentabelle';
$groessentabelle_url: '/s01_hi.php?h=11&s=de';
$haarverlust: 'Haarverlust';
$huete: 'Hüte';
$iban: 'IBAN:';
$ihre_auswahl: 'Ihre Auswahl: ';
$ihre_bestellungen: 'Ihre Bestellungen';
$ihre_bestellung_wird_verarbeit: 'Ihre Bestellung wird verarbeitet.';
$ihre_lieferadresse: 'Ihre Lieferadresse';
$ihre_rechnungsadresse: 'Ihre Rechnungsadresse';
$ihre_suche_ergab_leider_keine: 'Ihre Suche ergab leider keine Treffer.';
$ihre_zahlart: 'Ihre Zahlart :';
$im_store_verfuegbar: 'Im Store verfügbar:';
$inkl_mwst_gratisversand: 'inkl. Mwst. GRATISVERSAND';
$in_den_warenkorb: 'IN DEN WARENKORB';
$kaelte: 'Kälte';
$kategorien: 'Kategorien';
$keine_bestellungen: 'Sie haben noch keine Bestellungen.';
$kontakt: 'Kontakt';
$kontakt_support: 'Kontakt & Support';
$kontakt_url: 'Kontaktformular.html';
$konto_erstellen: 'Konto erstellen';
$kostuem: 'Kostüm';
$kundenbewertungen: 'Kundenbewertungen';
$k_nnte_ihnen_auch_gefallen: 'Könnte Ihnen auch gefallen:';
$lade: 'Lade';
$lastschrift_logo: 'https://cdn.lierys.com/lastschrift.svg';
$laufen: 'Laufen';
$lieferzeit: '<strong>1 - 2 Tage</strong><br>Lieferzeit';
$lierys_liste: '			<li>Lierys kombiniert bekannte Formen, qualitativ hochwertige Materialien und Funktionalität. Doch auch				ausgefallene Stilelemente werden gerne mal als Highlights eingesetzt, um Produkten optisch den letzten				Schliff zu geben.</li>			<li>Viele Strickmützen und eine Reihe extravaganter Kopfbedeckungen werden in Deutschland entworfen und				produziert. </li>			<li>Andere Kopfbedeckungen, wie beispielsweise Trendhüte und Visoren, bezaubern durch italienischen Charme				und tragen die Qualitätsbezeichnung Made in Italy. </li>			<li>Die Schwertlilie auf dem Logo symbolisiert Unschuld und Reinheit. Diese Werte finden sich im zeitlos				schicken Design vieler Hüte, Mützen und Accessoires von Lierys wieder. </li>';
$lierys_zitat: 'Lierys bedeutet für mich, trendige und neue Akzente mit zeitlosen Kopfbedeckungen zu kombinieren.			Jeder kann frischen Wind in seinen individuellen Style hineinbringen, während die hohe Qualität der Produkte			stets sichergestellt ist. Klassische Designs mit modernen Akzenten  das trifft es auf den Kopf!\" <footer>				<cite>Andre Beelmann, Geschäftsführer der ANTHEC GmbH & Co. KG</cite></footer>';
$login_url: '/s01.php?cur=eur&sp=de&ag=1&pp=bue';
$marketing: 'Marketing';
$mehr: 'Mehr:';
$mehrere_antworten_m_glich: '* Mehrere Antworten möglich.';
$mehr_ohne_dp: 'mehr ';
$mein_konto: 'Mein Konto';
$merkzettel_url: '/s01.php?sp=de&ag=1&pp=perart';
$muetzen: 'Mützen';
$naesse: 'Nässe';
$neu: 'NEU';
$neuheiten_entdecken: 'Neuheiten entdecken';
$newsletter_anmeldung: 'Newsletter-Anmeldung';
$nl_anmeldung_url: 'Newsletter-Anmeldung.html';
$notwendig_eigene_cookies: 'Notwendig (eigene Cookies)';
$nur_notwendige_cookies: 'Nur notwendige Cookies';
$oder_bezahlen_mit: 'oder bezahlen mit';
$one_size: 'One Size';
$passwort: 'Passwort';
$passwort_vergessen: 'Passwort vergessen?';
$passwort_wdh: 'Passwort wdh.';
$passwort_zur_cksetzen: 'PASSWORT ZURÜCKSETZEN';
$paypal_logo: 'https://cdn.lierys.com/paypal.svg';
$preis: 'Preis';
$produktbeschreibung: 'Produktbeschreibung';
$produktdetails: 'Produktdetails';
$rechnungsadresse: 'Rechnungsadresse';
$rechnung_logo: 'https://cdn.lierys.com/rechnung.svg';
$sale: 'SALE';
$schlie_en: 'Schließen';
$schreiben_sie_uns_eine_e_mail: 'Schreiben Sie uns eine E-Mail.';
$service: 'Service';
$shopname_footer: 'Lierys.com';
$sonne: 'Sonne';
$sonstiges: 'Sonstiges...';
$sport: 'Sport';
$sprache-de: 'Deutsch';
$sprache-en: 'Englisch';
$sprache-icon: 'lierys-icon-de.svg';
$sprache-kurz: 'DE';
$sprache_deutsch: 'DE';
$sprache_englisch: 'EN';
$startseite_headline_2: 'Was macht unsere Marke besonders?';
$startseite_headline_3: 'Was erwartet mich im Sortiment von Lierys?';
$startseite_headline_seo: 'Lierys  zeitlose und elegante Kopfbedeckungen mit Extravaganz';
$startseite_seo_1: 'Sie suchen eine Beanie für den Alltag, einen Schal für die kühle Winterzeit und für das nächste Fest noch			einen Fascinator? Dann haben wir einen heißen Tipp: Stöbern Sie doch mal im breiten Sortiment unserer Marke			Lierys! Hier finden Damen und Herren jeder Altersklasse hochwertige Kopfbedeckungen unterschiedlichster			Stilrichtungen, inklusive dem nötigen Zubehör. So ist für jeden Style und für jede persönliche Vorliebe eine			geeignete Mütze, Cap oder ein passender Hut dabei. ';
$startseite_seo_2: 'Genau diese Frage haben wir auch unserem Geschäftsführer, dem Schöpfer von Lierys, gestellt:';
$startseite_seo_3: 'Für die trendbewusste Frau gibt es moderne Glocken- und Sonnenhüte aus Stroh oder Leinen für den warmen			Sommer. Vor allem der Strohhut ist besonders luftdurchlässig und somit zu der Jahreszeit sehr angenehm.			Gegen die Kälte im Winter können Sie einen Hut aus Wollfilz perfekt mit einem stylischen Strick- oder			Loopschal kombinieren. Stirnbänder und Ohrenschützer von Lierys sind durch die hochwertige Baumwolle			besonders wärmend und runden jedes Outfit ab.';
$startseite_seo_4: 'Der stilsichere Mann kann sich mit einem ausgefallenen Pork Pie, Traveller-, Fedora-, Trilby- oder Panamahut			beglücken. Wer es noch eleganter möchte, kann auch zur Melone (engl. Bowler) oder zum Zylinder aus unserem			Sortiment greifen. Mit den klassischen Flatcaps oder anderen Schirmmützen hingegen liegt man absolut im			lässigen urbanen Trend. Alternativ kann auch der maritime Elbsegler von Lierys Einzug in Ihre Garderobe			finden. Damit Sie auch möglichst lange etwas von Ihrem neuen Lieblingsstück haben, können Sie eine			Hutschachtel aus unserem Hause zur Aufbewahrung nutzen.';
$startseite_seo_5: 'Schließlich bieten wir auch für die Kleinen, neben anderen Modellen in Kindergröße, süße Bommelmützen, die			einen festen Halt am Kopf haben und zudem zauberhaft aussehen. So steht dem nächsten abenteuerlichen Ausflug			bei Schnee nichts mehr im Wege.';
$startseite_seo_6: 'Worauf warten Sie noch? Tauchen Sie ein in die extravagante und zeitlose Welt von Lierys!';
$status: 'Status';
$suchen: 'Suchen ...';
$summe: 'Summe:';
$teillieferung_nur_mit_dhl: 'Teillieferung nur mit DHL möglich.';
$tel: 'Tel.:';
$telnummer: '0251 93216990';
$trend_pers_nlicher_stil: 'Trend, persönlicher Stil ...';
$umtauschrecht: '<strong>100 Tage</strong><br>Umtauschrecht';
$unsere_informative_hotline: 'Unsere informative Hotline.';
$unternehmen: 'Unternehmen';
$url: '/';
$urlaub: 'Urlaub';
$url_kontaktformular: 'Kontaktformular.html';
$versand: 'Versand';
$versandadresse: 'Versandadresse';
$versandkosten: 'Versandkosten';
$vielen_dank_fuer_ihren_einkauf: 'Vielen Dank für Ihren Einkauf.';
$vielen_dank_f_r_die_teilnahme: 'Vielen Dank für die Teilnahme!';
$vorkasse_logo: 'https://cdn.lierys.com/pay_vorkasse.svg';
$waehrung: 'EUR';
$wandern: 'Wandern';
$warenkorb_url: '/Warenkorb.html';
$web_performance_und_analyse: 'Web Performance und Analyse';
$weiterlesen: 'weiterlesen';
$weiter_mit_seite_aktuelleseite: 'Weiter mit Seite #aktuelleSeite von #MaxSeiten';
$wetterschutz: 'Wetterschutz';
$widerrufsrecht: 'Widerrufsrecht';
$widerrufsrecht_url: 'Rueckgaberecht.html';
$wuenschen_sie_teillieferung: 'Wünschen Sie eine <span>kostenlose</span> Teillieferung?';
$zahlungsart: 'Zahlungsart';
$zahlungsarten: 'Zahlungsarten';
$zahlungsart_aendern: 'Zahlungsart ändern';
$zum_kontaktformular: 'Zum Kontaktformular';
$zur_bestelluebersicht: 'Zur Bestellübersicht';
$zur_ck_zur_bersicht: 'Zurück zur Übersicht';$local_gulp: null;// helpers -> Müssen zuerst importiert werden
@import "helpers/variables";

// base
@import "base/main";
