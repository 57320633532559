/* ==========================================================================
12. _Checkout
========================================================================== */

#exit .wk_zk_div {
    margin: 0 0 20px 0;
}

.wk_zk_div > table > tbody {
    display: flex;
    flex-direction: column;
}

.wk_zk_div > table > tbody > tr:nth-of-type(1) {
    flex-basis: 100%;
}

.wk_zk_div > table > tbody > tr:nth-of-type(1) > td {
    width: 100%;
    display: flex;
}

.wk_zk_div > table > tbody > tr:nth-of-type(2) span.b.zk_sum {
    font-size: 1rem;
}

.wk_zk_div > table > tbody > tr:nth-of-type(3),
.wk_zk_div > table > tbody > tr:nth-of-type(4) {
    align-self: flex-end;
}

.wk_zk_div > table > tbody > tr:nth-of-type(2) > td,
.wk_zk_div > table > tbody > tr:nth-of-type(3) > td,
.wk_zk_div > table > tbody > tr:nth-of-type(4) > td {
    display: block;
    font-size: 1.4rem;
}

.wk_zk_div .b.zk_vers b {
    font-size: 1rem !important;
}

.zk_vers b span {
    font-size: 1rem !important;
}

/*
span.b.zk_vers:after {
    content: 'GRATIS VERSAND';
    display: block;
    color: $color-primary;
    font-size: 1rem;
    font-weight: 700;
}
*/

.wk_zk_div > table > tbody > tr > td span.zk_sum b span,
.wk_zk_div > table > tbody > tr > td span.zk_vers b span,
.wk_zk_div > table > tbody > tr > td span.zk_gsum b span {
    font-size: 1.4rem;
    color: $dunkeltext;
    font-weight: 100;
}

.wk_zk_div .b b {
    font-weight: 100;
}

.wk_zk_div hr {
    border: none;
    border-top: 2px solid #ddd;
    margin: 10px 0 0 20px;
}

#kaufabschlussButton {
    background: $color-primary;
    border-color: $color-primary;
    color: #fff;
    padding: 15px 80px;
    transition: all 1s ease-in-out;
    font-size: 1.5rem;
    margin: 10px 0 0 0;
    font-weight: 100;
}

#kaufabschlussButton:hover {
    background: $color-primary;
    border-color: $color-primary;
    transition: all 1s ease-in-out;
}

.wk_zk_div > table > tbody > tr:nth-of-type(3),
.wk_zk_div > table > tbody > tr:nth-of-type(3),
.wk_zk_div > table > tbody > tr:last-child {
    align-self: flex-end;
}

.wk_zk_div > table > tbody > tr:last-child {
    font-size: 22px;
}

@media (max-width: 768px) {
    div.wk_zk_div > table > tbody > tr {
        width: auto !important;
    }
}

@media (max-width: 576px) {
    #kaufabschlussButton {
        width: 100%;
    }
}
