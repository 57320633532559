/* ==========================================================================
09. Login
========================================================================== */

.div_login {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.div_login div[class*="login_"] {
    background: $dark;
    border: none;
    border-top: 2px solid $color-primary;
}

.login_a,
.login_b {
    margin: 20px 0;
    min-width: 49%;
}

.login_c {
    margin: 0;
    width: 100%;
}

.div_login tr td {
    display: flex;
    height: initial;
    margin: 5px 0;
    flex-wrap: wrap;
}

.div_login tr td input {
    width: 100% !important;
}

.div_login tr td label input {
    width: auto !important;
}

.div_login table {
    min-width: 350px;
}

.login_ueb {
    font-size: 1.3rem;
    font-weight: 400;
    color: $color-primary;
}

#pp_guestco .login_ueb {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

#shopLayer[data-online="1"] .menu-right .login a span.dot {
    position: relative;
}

#shopLayer[data-online="1"] .menu-right .login a span.dot:before {
    content: "";
    background: $color-primary url(#{$cdnPfad}/icon_check_w.svg) 50% 50% no-repeat;
    background-size: 80%;
    width: 20px;
    border-radius: 50%;
    box-sizing: border-box;
    color: #fff;
    height: 20px;
    display: block;
    text-align: center;
    position: absolute;
    right: -4px;
    top: 27px;
    border: 2px solid #fff;
    cursor: pointer;
    pointer-events: none;
}

.za_dk-div [class^="text_za_dk"] {
    color: $color-primary;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 110%;
    padding: 0 0 0 30px;
}

.za_dk-div [class^="button_za_dk"] {
    background-image: none;
    display: none;
}

#schnellog_za_table .ala {
    text-align: left;
    margin: 30px 0 0 0;
}

div#packst_grafik {
    display: none !important;
}

table#schnellog_za_table tr:nth-of-type(4) td:first-child div {
    margin-top: 0 !important;
}

ul#schnellog,
ul#ala_schnell {
    display: flex;
    padding: 20px 0 0 0;
    list-style-type: none;
    flex-wrap: wrap;
}

ul#schnellog li,
ul#ala_schnell li {
    flex-basis: 350px;
    margin: 10px;
}

ul#schnellog label,
ul#ala_schnell label {
    width: 100%;
    text-align: left;
}

li#schnellLog_feld5 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

li#schnellLog_feld5 * {
    width: 50% !important;
}

li#schnellLog_feld5 label:nth-of-type(1) {
    order: 1;
    width: 60% !important;
    margin-right: 10%;
}

li#schnellLog_feld5 input:nth-of-type(1) {
    order: 3;
    width: 60% !important;
    margin-right: 10%;
}

li#schnellLog_feld5 label:nth-of-type(2) {
    order: 2;
    width: 30% !important;
}

li#schnellLog_feld5 input:nth-of-type(2) {
    order: 4;
    width: 30% !important;
}

li#schnellLog_feld1,
li#schnellLog_feld8 {
    display: flex;
    flex-direction: column;
}

.div_login select {
    height: 35px;
}

#pp_guestco form {
    max-width: 800px;
    margin: 0 auto;
}

li#schnellLog_feld12 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#schnellLog_feld12 select {
    align-items: baseline;
    flex-basis: 32.5%;
    width: min-content;
}

table#schnellog_za_table tr:nth-of-type(2) td:nth-of-type(2) {
    display: flex;
    flex-wrap: wrap;
}

.za_dk-div {
    position: relative;
    overflow: hidden;
    float: none;
    width: 44%;
    margin-top: 6px;
    cursor: pointer;
    margin: 20px 20px 0 0;
    box-sizing: border-box;
    display: flex;
}

.login_c input[type="radio"] + label,
.login_c input[type="radio"]:checked + label {
    background-color: #fff;
    transition: background 0.5s;
    border: none;
    border-radius: 0;
    padding: 20px;
    width: 87%;
    border: 2px solid #fff;
}

.login_c input[type="radio"]:checked + label {
    border: 2px solid $color-primary;
}

#schnellog_za_table .za_dk-div input {
    width: auto !important;
}

.div_login #schnellog_za_table tr:last-child td button {
    max-width: 200px;
    margin: 30px 0;
}

.div_login #schnellog_za_table tr:last-child td {
    flex-direction: column-reverse;
}

.za_dk-div .ala_label {
    text-align: left;
    line-height: 130%;
    padding: 20px 20px 20px 50px !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
}

.login_c input[type="radio"] + label:before {
    transition: background 0.5s;
    position: absolute;
    transform: translate(0%, -50%);
    margin-right: 10px;
    display: block;
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    content: " ";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: 50%;
    left: 15px;
    border: 1px solid #bbb;
    background: white;
    margin: 2px 1em 4px 0.2em;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    visibility: visible;
}

.login_c input[type="radio"]:checked + label:before {
    background: $color-primary;
    box-shadow: inset 0 0 0 2px white;
}

@media (max-width: 576px) {
    .div_login div[class*="login_"] {
        width: 100%;
    }
}
