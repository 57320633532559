// Warenkorb: Übersicht und Kaufbutton klicken / Gutschein einlösen ...
.wk_pen {
    display: none;
}

.anzahl_in_wk {
    pointer-events: none;
}

.wk_tab_summe {
    display: flex;
    padding: 0 10px;

    tbody {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    tr,
    td {
        display: flex;
        width: 100%;
    }

    #wk_summe {
        .wk_gsumme {
            width: 100%;

            b {
                display: block;
            }
        }
    }

    #wk_bottom {
        display: flex;

        .trustedshops {
            margin-right: 20px;

            .zertifiziert {
                color: $helltext;
                width: 100%;
                font-size: 14px;
            }

            .siegel {
                width: 100%;
            }

            img {
                margin-top: 15px;
                max-width: 120px;
            }

            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        .vorteile {
            margin-right: 20px;

            .container {
                display: flex;
                align-items: center;
                padding: 8px 0 8px 25px;
            }

            img {
                padding-left: 25px;
            }

            .pro {
                width: 100%;
                color: $helltext;
                font-size: 14px;
                text-align: left;
                padding-left: 35px;
            }

            .date {
                color: $color-primary;
            }
        }

        .bezahlmethoden {
            justify-content: flex-end;

            b {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}

.wk_wk_leer ~ .trustedshops,
.wk_wk_leer ~ .vorteile {
    display: none;
}

@media (max-width: 1280px) {
    .wk_tab_summe {
        #wk_bottom {
            .vorteile {
                img {
                    padding-left: 0;
                }
            }
        }
    }
}

@media (min-width: 1281px) {
    .wk_tab_summe {
        #wk_bottom {
            .vorteile {
                max-width: 360px;
            }
        }
    }

    .wk .wk_anz_td {
        min-width: 115px;
    }
}

@media (max-width: 1024px) {
    .wk_tab_summe {
        #wk_bottom {
            .trustedshops {
                margin: 0;
            }

            .vorteile {
                margin: 20px 0 0 0;
            }

            .bezahlmethoden {
                justify-content: flex-end;
                order: -1;
                margin-bottom: 55px;
            }
        }
    }
}

@media (min-width: 1025px) {
    .wk_tab_summe {
        #wk_bottom {
            .trustedshops {
                min-width: 220px;
                flex: 0 1;
            }

            .trustedshops,
            .vorteile {
                margin: -30px 20px 30px 0;
                background: rgba(0, 0, 0, 0.03);
                padding: 30px 0;
            }
        }
    }
}

@media (max-width: 576px) {
    .wk_tab_summe {
        #wk_bottom {
            .vorteile {
                img {
                    padding-left: 30px;
                }
            }
        }
    }
}

/* ==========================================================================
08. Warenkorb
========================================================================== */

[data-pp="wk"] .tab_k {
    display: none;
}

[data-pp="wk"] #wk_topping {
    margin: 30px auto 20px auto;
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
}

img.wk_icon {
    width: 24px;
    margin: 0 13px -1px 4px;
}

p#wk_anz_ueb {
    font-size: 0.9rem;
    margin: 5px 10px 15px 10px;
    color: $helltext;
}

p#wk_anz_ueb i {
    font-style: normal;
}

section.fortschritt {
    /*pointer-events:none;*/
    cursor: default;
}

section.fortschritt ul {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    background: $dark;
    margin: 20px 0;
    overflow: hidden;
}

.fortschritt ul li {
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    position: relative;
}

.fortschritt ul li.active {
    background: $color-primary;
}

section.fortschritt ul li a {
    color: $dunkeltext;
    padding: 3px 20px 4px;
}

section.fortschritt ul li.active a {
    color: #fff;
    position: relative;
    z-index: 1;
}

.fortschritt li.active.last a {
    border: 4px solid #fff;
    background: #fff;
    color: #999;
    margin-right: -16px;
}

.breadcrumb_wk {
    display: none;
}

.wk_wk_leer + hr {
    display: none;
}

.wk_wk_leer {
    text-align: center;
    margin: 20px 0 50px 0;
    background: $dark;
    padding: 40px;
}

span.artikelnummer_in_wk {
    font-size: 12px;
    display: block;
}

.wk_best_2,
.wk_best_1 {
    display: block;
    color: $color-primary;
}

.wk_best_2 span,
.wk_best_3 {
    color: $rot;
}

.fortschritt ul li.active + li.active:before {
    content: "";
    height: 50px;
    width: 400%;
    background: $color-primary;
    display: block;
    position: absolute;
    right: 80%;
    z-index: 0;
}

.fortschritt ul li:last-child {
    padding-right: 10px;
}

.fortschritt ul li:first-child {
    padding-left: 10px;
}

.fortschritt ul li:first-child {
    padding: 0 5px;
}

.fortschritt ul > li.active.last:first-child a {
    margin: 0 -10px 0 0;
}

.fortschritt ul li.active:after {
    content: "";
    background: url(#{$cdnPfad}/icon_check_w.svg) 0 0 no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
    display: inline-block;
    position: absolute;
    z-index: 10;
    top: 15px;
    right: -11px;
}

.fortschritt ul li.active.last:after {
    background-image: none;
}

.fortschritt li.active.last:last-child a {
    margin-right: -5px;
}

.fortschritt ul li.active.last:after {
    background-image: none;
    background: -moz-linear-gradient(left, rgba(134, 214, 142, 0) 0%, $color-primary 100%);
    background: -webkit-linear-gradient(left, rgba(134, 214, 142, 0) 0%, $color-primary 100%);
    background: linear-gradient(to right, rgba(134, 214, 142, 0) 0%, $color-primary 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0086d68e', endColorstr='#51CDB6',GradientType=1 );
    height: 50px;
    top: 0;
    right: -10px;
    width: 350%;
    z-index: 0;
}

.fortschritt ul li.active:nth-last-of-type(2) {
}

.wk_plus_minus img,
.wk_loe {
    max-width: 20px;
}

span.wk_plus_minus a:hover img,
.wk_loe:hover {
    transform: scale(1.2);
    transition: all 200ms ease-in-out;
}

img.wk_pic,
img.wk_zk_pic,
td.zk_wk_pic img {
    max-width: 80px;
}

.wk_zk .zk_wk_lager {
    display: none;
}

input.input_wk {
    padding: 0;
    border: 0;
    box-shadow: none;
    background: transparent;
    width: 60px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    color: #000;
}

table.wk,
table.wk_zk {
    background: $dark;
    padding: 50px 20px 0px 20px;
}

table.wk a.sc_link {
    display: block;
}

table.wk .name_in_wk,
table.wk_zk .name_in_wk {
    color: $dunkeltext;
    font-size: 1.2rem;
    font-weight: 100;
    text-align: left;
    display: block;
}

span.name_in_wk {
    padding: 0 10px 0 0;
}

table.wk > tbody > tr,
table.wk_zk > tbody > tr {
    display: flex;
    justify-content: space-between;
    margin: 0 0 40px 0;
    border-bottom: 1px solid $hellgrau;
    padding: 0 0 20px 0;
}

table.wk > tbody > tr:last-child,
table.wk_zk > tbody > tr:last-child {
    border: none;
    margin: 0 0 15px 0;
}

.weitkaufbutt {
    display: none !important;
}

span.b.wk_gsumme {
    font-size: 1.6rem;
    margin: 16px 20px 5px 16px;
    display: inline-block;
    position: relative;
}

span.b.zk_gsum {
    position: relative;
}

span.b.wk_gsumme span,
span.b.zk_gsum b b {
    font-size: 0.7rem;
    color: #888;
    position: absolute;
    right: 0;
    bottom: -14px;
    font-weight: 100;
}

.wk_zk td:nth-of-type(3),
.wk_zk td:nth-of-type(7) {
    display: none;
}

table.wk tr td,
table.wk_zk tr td {
    flex-basis: 0;
    flex-grow: 1;
    position: relative;
}

.wk .wk_pic_td,
.wk_zk .zk_wk_pic {
    order: 1;
    flex-grow: 0;
    margin: -10px 20px 0 0;
}

.wk .wk_anz_td,
.wk_zk .zk_wk_anz {
    order: 3;
    flex-grow: 0;
}

.wk .wk_name_td,
.wk_zk .zk_wk_name {
    order: 2;
    flex-grow: 2;
    text-align: left;
}

.wk .wk_preis_td,
.wk_zk .zk_wk_ep {
    order: 4;
    pointer-events: none;
}

.wk .wk_ges_preis_td,
.wk_zk .zk_wk_gsum {
    order: 5;
    pointer-events: none;
}

td.wk_preis_td br,
td.wk_ges_preis_td br,
td.zk_wk_ep br,
td.zk_wk_gsum br {
    display: none;
}

.wk_preis_td span.a,
.wk_ges_preis_td span.a,
td.zk_wk_gsum span.a,
td.zk_wk_ep span.a {
    display: block;
}

.wk_ges_preis_td strike {
    display: block;
}

span.a3 .wk_gespart,
td.zk_wk_ep .a3 nobr,
td.zk_wk_gsum .a3 nobr {
    display: none;
}

.wk_preis_td span.preis_in_wk_strike,
.a3 strike,
.a3 .wk_ges_preis_td span span,
.wk_ges_preis_td span.ges_preis_in_wk_strike {
    font-size: 1rem;
    color: $rot;
}

.wk_preis_td span span,
.wk_ges_preis_td span span,
.zk_wk_ep span.preis_in_wk,
.zk_wk_gsum .preis_in_wk,
.zk_wk_gsum .gespreis_in_wk {
    font-size: 1.3rem;
    font-weight: 100 !important;
}

span.b3.wk_rab_zeile.wk_gsumme {
    display: block;
    margin: 10px 22px -10px 0;
}

.wk .wk_pic_td:before,
.wk_zk .zk_wk_pic:before {
    content: "";
    height: 80px;
    width: 100%;
    background: $dark;
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
}

.wk_anz_td tr {
    display: flex;
    flex-direction: row;
}

.wk_anz_td tr td {
    width: auto !important;
}

span.wk_plus_minus {
    margin: 6px 0 0 10px;
    display: inline-block;
}

table.wk .wk_lief_td {
    display: none;
}

.wk tr.wk_ueberschrift_tr,
.wk_zk tr.tabelle_zk_th {
    display: none;
}

.wk .wk_name_td:after,
.wk_zk .zk_wk_name:after {
    content: $artikel;
    display: block;
    position: absolute;
    left: 2px;
    top: -20px;
    font-size: 0.7rem;
    color: $helltext;
}

.wk_zk .zk_wk_anz span.a {
    height: 35px !important;
    border: 1px solid #dddddd;
    width: 60px !important;
    display: inline-block;
    line-height: 35px;
}

.wk .wk_anz_td:before,
.wk_zk .zk_wk_anz:before {
    content: $anzahl;
    display: block;
    position: absolute;
    left: 13px;
    top: -20px;
    font-size: 0.7rem;
    color: $helltext;
}

.wk .wk_preis_td:before,
.wk_zk .zk_wk_ep:before {
    content: $preis;
    display: block;
    position: absolute;
    right: 15px;
    top: -20px;
    font-size: 0.7rem;
    color: $helltext;
}

.wk .wk_ges_preis_td:before,
.wk_zk .zk_wk_gsum:before {
    content: $gesamt;
    display: block;
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 0.7rem;
    color: $helltext;
}

.wk_hr_ueber_gsum {
    display: none;
}

.wk_tab_summe .zzgl_Vers_in_WK {
    display: none;
}

.wk .wk_mwst,
.wk_zk .wk_mwst {
    display: none;
}

#wk .tabelle_zk {
    position: absolute;
    left: -5000px;
}

.wk_zk_div {
    padding: 0;
    border: none;
    margin: 0;
    background-color: initial;
}

#wk_gutscheincode button {
    padding: 5px 30px;
    font-size: 0;
    border-color: $hellgrau;
}

#wk_gutscheincode {
    max-width: 350px;
    display: flex;
}

form#wk_gutscheincode button img {
    width: 20px;
}

input#gutschein_code {
    width: 212px;
    height: 44px !important;
    margin: 0 10px 0 0;
    font-size: 14px;
}

.wk_tab_summe a.wk_zk_button {
    background: $color-primary;
    border-color: transparent;
    color: #fff;
    padding: 5px 80px;
    margin: 20px 0 50px 0;
    font-size: 1.6rem;
    min-width: 122px;
}

form #wk_gutscheincode button {
    border-color: $hellgrau;
}

form #wk_gutscheincode button img {
    width: 14px;
}

table#wk tr td form table:nth-of-type(2) tbody tr td table tbody tr {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.free_delivery {
    color: $color-primary;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 15px 0 0;
    display: inline-block;
}

#pp_express_link,
div#LoginWithAmazon {
    padding: 25px 20px;
    margin: 20px 0 10px 0;
    font-size: 1.2rem;
    width: 286px !important;
    height: 30px;
    display: inline-block;
    box-sizing: border-box;
}

div#LoginWithAmazon {
    margin: 0;
    padding: 0;
}

.login_a div#LoginWithAmazon img {
    width: 152px !important;
}

#pp_express_link {
    background: #eee url(#{$cdnPfad}/icon_paypal.svg) 50% 50% no-repeat;
    background-size: auto 100%;
    margin-top: 0;
}

.wk_tab_summe a.wk_zk_button:after {
    content: $oder_bezahlen_mit;
    color: #999;
    display: block;
    font-size: 0.9rem;
    font-weight: 100;
    border-top: 1px solid #ddd;
    text-align: end;
    padding-top: 0;
    margin: 30px 0 10px 0;
    position: absolute;
}

div#LoginWithAmazon img {
}

.wk_name_td .best_1 {
    display: block;
}

@media (max-width: 760px) {
    body #LoginWithAmazon img {
        width: 100%;
        max-height: initial !important;
    }

    body #LoginWithAmazon {
        height: auto;
    }
}

#pp_express_link img {
    display: none;
}

table.wk_tab_summe tr:nth-of-type(4) span b {
    display: flex;
    flex-direction: column;
    flex: 1;
}

table.wk_tab_summe tr:nth-of-type(4) span b * {
    align-self: end;
}

figure.artikel_image.tZoom.basketFlyer {
    position: absolute;
    top: 750px;
    transition: all 3s ease-in-out;
    background: $color-primary;
    border-radius: 20px;
    width: 20px !important;
    height: 20px !important;
}

.basketFlyer img.artikel-detail_img {
    width: 20px;
    mix-blend-mode: multiply;
}
