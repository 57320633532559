/* catamaran-100 - latin */
@font-face {
    font-family: "Catamaran";
    font-style: normal;
    font-weight: 100;
    src:
        local("Catamaran Thin"),
        local("Catamaran-Thin"),
        url("https://css.lierys.com/bin/font/catamaran-v6-latin-100.woff2") format("woff2"),
        url("https://css.lierys.com/bin/font/catamaran-v6-latin-100.woff") format("woff"); /* Modern Browsers */
}

/* catamaran-regular - latin */
@font-face {
    font-family: "Catamaran";
    font-style: normal;
    font-weight: 400;
    src:
        local("Catamaran Regular"),
        local("Catamaran-Regular"),
        url("https://css.lierys.com/bin/font/catamaran-v6-latin-regular.woff2") format("woff2"),
        url("https://css.lierys.com/bin/font/catamaran-v6-latin-regular.woff") format("woff"); /* Modern Browsers */
}

/* catamaran-700 - latin */
@font-face {
    font-family: "Catamaran";
    font-style: normal;
    font-weight: 700;
    src:
        local("Catamaran Bold"),
        local("Catamaran-Bold"),
        url("https://css.lierys.com/bin/font/catamaran-v6-latin-700.woff2") format("woff2"),
        url("https://css.lierys.com/bin/font/catamaran-v6-latin-700.woff") format("woff"); /* Modern Browsers */
}

/* crimson-text-regular - latin */
@font-face {
    font-family: "Crimson-Text";
    font-style: normal;
    font-weight: 400;
    src:
        local("Crimson Text Regular"),
        local("CrimsonText-Regular"),
        url("https://css.lierys.com/bin/font/crimson-text-v10-latin-regular.woff2") format("woff2"),
        url("https://css.lierys.com/bin/font/crimson-text-v10-latin-regular.woff") format("woff"); /* Modern Browsers */
}

/* crimson-text-700 - latin */
@font-face {
    font-family: "Crimson-Text";
    font-style: normal;
    font-weight: 700;
    src:
        local("Crimson Text Bold"),
        local("CrimsonText-Bold"),
        url("https://css.lierys.com/bin/font/crimson-text-v10-latin-700.woff2") format("woff2"),
        url("https://css.lierys.com/bin/font/crimson-text-v10-latin-700.woff") format("woff"); /* Modern Browsers */
}
