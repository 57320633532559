#shopLayer[data-pp*="aar"] {
    border-top: 10px solid $color-primary;
}

form[name="ratt"] table tbody tr {
    display: flex;
    flex-basis: 100%;
    margin: 0 0 10px 0;
}

form[name="ratt"] table tbody tr td {
    min-width: 130px;
    margin: 0 10px 0 0;
}

form[name="ratt"] table tbody tr:nth-child(1) td {
    width: 100%;
    margin: 0 0 20px 0;
}

form[name="ratt"] table tbody tr:nth-child(12) {
    display: none;
}

input.cssBewSenden {
    border-radius: 30px;
}

.separate-rating .rating-date {
    opacity: 0;
    transition:
        visibility 0s,
        opacity 0.3s linear;
}

.separate-rating:hover .rating-date {
    display: inline-block;
    visibility: visible;
    opacity: 1;
}
