/* ==========================================================================
21. 404 Fehlerseite
========================================================================== */

.fehlercode {
    padding: 30px 0;
}

.fehlercode .fehler-404 {
    width: 280px;
    margin: 0 auto;
    padding-top: 40px;

    img {
        max-width: 300px;
    }
}

.fehlercode .fehler-text {
    color: #bbbcbb;
    font-size: 24px;
    width: 100%;
    text-align: center;
    padding: 40px 0;
}

.fehlercode button {
    color: $color-primary;
    border: 1px solid $color-primary;
    font-weight: 300;
    background: #fff;
}

.fehlercode span {
    padding: 0 40px;
    color: #bbbcbb;
}

.fehlercode .fehler-optionen {
    width: 100%;
    text-align: center;
}

.fehlercode .search::after {
    top: 12px;
}

.fehlercode .search {
    display: inline-block;
    position: relative;
    top: -28px;
    height: unset;
}

.fehlercode .search,
.fehlercode button {
    width: 220px;
    font-size: 18px;
}

#shopLayer .fehlercode input {
    height: 45px !important;
    padding-left: 25px;
}

#shopLayer .fehlercode input:focus {
    border: 1px solid $color-primary;
}

@media (max-width: 768px) {
    .fehlercode .search {
        margin: 0 auto;
    }

    .fehlercode .fehler-optionen {
        display: flex;
        flex-flow: column;
    }

    .fehlercode span {
        padding: 15px 0 40px 0;
    }
}
