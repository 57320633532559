.mobilizedMenue-MainContaner {
    .lvl_3 {
        > li {
            flex: 1 0 50%;
            padding: 8px 0;

            > a {
                padding: 0 0 0 10px;
            }
        }
    }

    ul:not(.men_aug) {
        .men_aug_5 {
            // Accessoires (zu langes Wort)
            a:after {
                display: none;
            }
        }
    }
}

.mobile_menu,
.mobile_search {
    display: none;
}

.mobilizedMenue-MainContaner {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    z-index: 100000;
    width: 100%;
    height: 100%;
    overflow: auto;
}

@keyframes mobilizedMenue-Silde-Anim {
    0% {
        transform: translate(-100%, 0%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

.mobilizedMenue-slide {
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 0.3s;
    animation-name: mobilizedMenue-Silde-Anim;
    animation-timing-function: linear;
    background: #f6f6f6;
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mobilizedMenue-MainContaner ul:first-child {
    flex: 0 0 auto;
    flex-direction: row-reverse;
    background: #4a4a4a;
}

.mobilizedMenue-MainContaner ul:first-child li {
    flex: 0 0 50%;
    min-height: 40px;
}

.mobilizedMenue-MainContaner ul:first-child li:only-child {
    flex-basis: 100%;
}

.mobilizedMenue-MainContaner ul:first-child li a {
    font-size: 1.2rem;
}

.mobilizedMenue-MainContaner ul {
    display: flex;
    flex-direction: column;
    flex: 1;

    &.lvl_3 {
        flex: inherit;
    }
}

.mobilizedMenue-MainContaner ul li {
    flex: 1;
    display: flex;
    align-items: center;
}

ul.mobilizedMenue-bottom {
    flex-direction: row;
    flex: 0 0 auto;
    background: $buttonborder;
    margin-top: -1px !important;
    flex-wrap: wrap;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li a {
    color: #fff;
}

/*
ul.men_aug li {
    flex: 1 0 auto;
}
*/

ul.men_aug2.lvl_3 {
    /*flex: 1 0 auto;*/
    flex-wrap: wrap;
    flex-direction: row;
}

/*
ul.men_aug2.lvl_3 li {
        flex: 1 0 50%;
}
*/

ul.men_aug2.lvl_3 li a {
    font-size: 1.1rem;
}

ul.men_aug2.lvl_3 li.men__h.head,
ul.men_aug2.lvl_3 li.men_aug2_h.head {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    font-size: 1.2rem;
    max-height: 40px;
    min-height: 40px;
    line-height: 98%;
    padding: 5px 0 25px 50px;
    box-sizing: border-box;
}

ul.men_aug2.lvl_3:empty {
    display: none;
}

.mobilizedMenue-MainContaner.closed {
    transition: transform 0.3s;
    transform: translate(-100%, 0);
}

.mobilizedMenue-MainContaner ul,
.mobilizedMenue-MainContaner li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobilizedMenue-MainContaner li a,
.mobilizedMenue-MainContaner li {
    font-size: 25px;
    color: #fff;
}

.mobilizedMenue-MainContaner li {
    border-bottom: 1px solid $hellgrau;
}

.mobilizedMenue-MainContaner li.head {
    text-align: center;
    padding: 15px 0 7px 0;
    background: $hellgrau;
    color: $color-primary;
}

.mobilizedMenue-MainContaner li a {
    padding: 0 0 0 35px;
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.mobilizedMenue-MainContaner li.head {
    color: #fff;
    background: $buttonborder;
    border: none;
    margin-top: -1px;
}

.mobilizedMenue-MainContaner .men_aug2.lvl_3.formen {
    order: 1;
}

.mobilizedMenue-MainContaner .men_aug2.lvl_3.material {
    order: 2;
}

.mobilizedMenue-MainContaner .men_aug2.lvl_3.witterung {
    order: 3;
}

.mobilizedMenue-MainContaner li a:after {
    content: " ";
    background: no-repeat 50% 50%;
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    text-align: center;
}

.mobilizedMenue-MainContaner li.mobilizedMenue-all a:after {
    background-image: url(#{$cdnPfad}/icon_breadcrum_arrow.svg);
    right: 13px;
}

.mobilizedMenue-MainContaner li.mobilizedMenue-back a:after {
    background-image: url(#{$cdnPfad}/icon_breadcrum_arrow.svg);
    transform: translateY(-50%) rotate(180deg);
    left: 7px;
}

.mobilizedMenue-MainContaner li.mobilizedMenue-close a {
    color: #fff;
}

.mobilizedMenue-MainContaner li.mobilizedMenue-close a:after,
.mobilizedMenue-MainContaner li.hasChildren:not(.mobilizedMenue-all) a:after {
    background-image: url(#{$cdnPfad}/icon_close_w.svg);
    left: initial;
    right: 15px;
}

.mobilizedMenue-MainContaner li.hasChildren:not(.mobilizedMenue-all) a:after {
    background-image: url(#{$cdnPfad}/icon_breadcrum_arrow.svg);
}

.mobilizedMenue-MainContaner ul.sub-extra {
    display: none;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li a,
.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li {
    font-size: 1.2rem;
    color: #fff;
    flex: 1 1 50%;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li a {
    padding: 10px;
    display: flex;
    line-height: 140%;
    justify-content: flex-start;
    align-items: center;
}

.mobilizedMenue-MainContaner li a,
.mobilizedMenue-MainContaner li {
    font-size: 1.5rem;
    color: $helltext;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li a:after {
    content: none;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li a:before {
    content: "";
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 20px;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li:nth-of-type(4) a:before {
    background: transparent url(#{$cdnPfad}/icon_stores_w.svg) no-repeat 50% 50%;
    background-size: contain;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li:nth-of-type(3) a:before {
    background: transparent url(#{$cdnPfad}/icon_service_w.svg) no-repeat 50% 50%;
    background-size: contain;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li:nth-of-type(2) a:before {
    background: transparent url(#{$cdnPfad}/icon_fav_w.svg) no-repeat 50% 50%;
    background-size: contain;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li:nth-of-type(1) a:before {
    background: transparent url(#{$cdnPfad}/icon_login_w.svg) no-repeat 50% 50%;
    background-size: contain;
}

button.mobilizedMenue {
    display: block;
    float: none;
    width: 40px;
    height: 40px;
    background: transparent url(#{$cdnPfad}/icon_menu.svg) no-repeat 50% 50%;
    box-shadow: none;
    z-index: 1000;
    border: none;
    background-size: contain;
    padding: 0;
    border-radius: 0;
}

#mobileMenue {
    width: 100%;
    height: 100%;
    position: absolute;
}

.mobilizedMenue-MainContaner li.head {
    padding: 0 0 0 30px;
}

#men_1 section.unten {
    position: absolute;
    left: -9999999px;
}

.mobilizedMenue-stores {
    display: none !important;
}

@media (min-width: 761px) {
    ul.men_aug2.lvl_3.equipment,
    ul.men_aug2.lvl_3.highlights {
        flex-direction: column;
    }
}
