#ps-suchleiste {
    display: flex;
    align-items: center;
    margin: 10px 0;
    z-index: 8;
    position: relative;
    top: 0 !important;

    #ps-suche-schliessen {
        background-size: 14px;
    }

    #suchStr {
        padding: 0 10px;
        width: 100%;
    }

    #ps-filter-toggle,
    #suchStr {
        height: 45px !important;
        line-height: 45px;
    }

    #ps-filter-toggle {
        padding: 0 0.8em;
        background: $color-primary;
        color: #fff;
        display: none;
    }
}

#ps-vorschlage {
    list-style-type: none;
    background: #f8f8f8;
    margin: -10px 0 0 0;

    > li.vorschlag-link {
        > a {
            margin: 0;
            padding: 0;
            font-size: 14px;
        }
    }
}

span.typo {
    margin: 0;
}

li.unimportant.inview.watched:before {
    content: $k_nnte_ihnen_auch_gefallen;
    display: block;
    width: 100%;
    text-align: left;
    margin: 25px 0 10px 0;
    font-size: 1.3rem;
    border-bottom: 1px solid #ccc;
}

div#shopMitte2 .top_bread-sort {
    display: none;
}

#ps-menu {
    position: unset;
    width: 100%;
    padding: 0;

    #ps-anti-filter {
        > ul {
            display: flex;
            list-style-type: none;
            flex-wrap: wrap;

            label {
                width: 100%;
                margin-right: 30px;
            }
        }
    }

    .see_alle_dd {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background: #f8f8f8;
        padding: 10px;
        box-sizing: border-box;
        margin: 10px 0;

        #ps-filter-reset {
            margin-bottom: 0;
            box-shadow: none;
            width: 100%;

            &:after {
                content: "";
                background-color: transparent;
            }
        }

        > ul {
            list-style-type: none;
            flex: 0 1 275px;
            background: #fff;
            margin: 10px;
            padding: 10px 18px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            cursor: pointer;

            button {
                border-radius: 0;
            }

            .dd_list {
                display: none;
            }

            a {
                color: #9b9b9b;
            }

            li div:first-child:after {
                content: " ";
                display: block;
                background: url(https://cdn.lierys.com/dropdown-arrow.svg) no-repeat (50% / contain);
                height: 24px;
                width: 24px;
                position: absolute;
                right: 0;
                top: 0;
                transition: transform 0.5s;
            }
        }
    }
}

#shopMitte2 {
    margin: 0 !important;

    .shop_tab_mitte {
        width: initial !important;
    }
}

#ps-weiteres {
    margin-top: -1px;
    top: 0 !important;
    position: relative;

    #ps-infos {
        display: none !important;
    }

    &.verstecken {
        display: block;
        position: relative;
        z-index: 7;
        top: 0 !important;
    }
}

#ps-menu-hintergrund {
    display: none;
}

/*** Suchbutton ***/
#ps {
    ul {
        list-style-type: none;
    }

    .search {
        top: -50px;
        right: 5px;
        width: 70px;
    }

    .hide {
        display: none;
    }

    #ps-button {
        button {
            background: transparent;
            border: none;
            height: 50px;
            color: #737981;
            width: 70px;
        }
    }

    @media screen and (max-width: 1024px) {
        .hide-1024 {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    #ps-suchleiste {
        margin: 0;
        width: 100% !important;

        #ps-filter-toggle,
        #suchStr {
            font-size: 14px;
        }
    }

    #ps-vorschlage {
        margin-left: 0;

        li,
        b,
        a {
            font-size: 14px;
        }
    }

    #ps-menu {
        width: unset;
        padding: 0;

        .see_alle_dd {
            > ul {
                flex: 1 100%;

                li div:first-child:after {
                    right: -10px;
                }
            }
        }

        * {
            font-size: inherit;
        }
    }

    #ps-suchleiste #ps-suche-schliessen {
        height: 50px;
    }

    div#ps-weiteres {
        width: 100%;
    }
}
