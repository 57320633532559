.css_grid {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 66.26667% 32.43333%;
    -ms-grid-columns: 66.26667% 32.43333%;

    /*grid-template-rows: 50% 50%;*/
    -ms-grid-rows: 50% 50%;
    grid-template-areas: "artikel_img basket" "artikel_info basket";
}

@media (max-width: 772px) {
    .css_grid div.basket {
        margin-left: 0;
    }

    .css_grid {
        grid-template-columns: 100%;
        grid-template-areas: "artikel_img" "basket" "artikel_info";
    }

    div.css_grid .basket {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;
    }

    .css_grid .artikel-detail_left.artikel_img {
        -ms-grid-row: 1;
    }

    .css_grid .artikel_info.artikel-detail_left {
        -ms-grid-row: 3;
    }

    .css_grid .artikel_info.artikel-detail_left {
        -ms-grid-column-span: 2;
    }

    .css_grid .artikel-detail_left.artikel_img {
        -ms-grid-column-span: 2;
    }
}

.css_grid .artikel_info.artikel-detail_left {
    -ms-grid-column-span: 2;
}

.css_grid .artikel_img {
    grid-area: artikel_img;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

.css_grid .basket {
    grid-area: basket;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    margin-left: 15px;
}

.css_grid .artikel_info {
    grid-area: artikel_info;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}
