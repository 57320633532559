/* Ticket-System */

#ticketTabelle table {
    width: 100%;
}

#ticketTabelle td,
#ticketTabelle th {
    border-bottom: 1px solid #d2d2d2;
    padding: 1rem;
}

#ticketTabelle tr:not(:first-child):hover {
    background-color: #f5f5f5;
}

#ticketTabelle tr:not(:first-child) {
    cursor: pointer;
}

#ticketAnzeige h3,
#ticketTabelle h3 {
    text-align: center;
    margin: 1rem 0;
}

#ticketAnzeige {
    margin-bottom: 5rem;

    > a {
        border: 2px solid $color-primary;
        border-radius: 100px;
        font-size: 0.9rem;
        font-weight: 600;
        padding: 10px 30px;
        margin: 20px 0 0 0;
        display: inline-block;
    }

    h3 {
        font-weight: 300;
        font-size: 1.4rem;
    }
}

#ticketInfo {
    padding: 1rem 0;
    background: $backgroundgrau;
}

#ticketInfo div {
    display: inline-block;
    width: 48%;
    text-align: center;
}

.ticketAnhang {
    background-color: #fff;
    padding: 0.5rem 0;
}

#ticketUnterhaltung {
    overflow-y: auto;
    margin-bottom: 2rem !important;
}

.outgoing,
.incoming {
    width: 85%;
    margin-top: 1rem;
}

.outgoing {
    float: right;
}

.incoming {
    float: left;
}

.incoming p,
.outgoing p {
    padding: 0.5rem;
}

.incoming p {
    background-color: #fff;
    border: 2px solid $helltext;
    border-radius: 30px;
    padding: 20px;
    margin: 0 0 4px 0;
}

.outgoing p {
    position: relative;
    background-color: #fff;
    border: 2px solid $color-primary;
    border-radius: 40px;
    padding: 20px;
    margin: 0 0 4px 0;
}

.message {
    width: 100%;
}

.message p {
    font-size: 1.25rem;
}

.messageIcon,
.messageDate {
    display: inline-block;
}

.messageDate {
    float: right;
}

.messageIcon,
.messageDate {
    font-size: 0.8rem;
    color: $helltext;
    margin: 0 30px;
}

#ticketAntwort,
#ticketUnterhaltung {
    margin: auto;
}

#antwortFeld {
    min-width: 100%;
    border: 2px solid $color-primary;
    border-radius: 30px;
    box-sizing: border-box;
    padding: 20px;
    font-size: 1.1rem;
    margin: 10px 0 0 0;
}

#ticketAntwortFehler {
    text-align: center;
    margin-top: 5rem;
    font-weight: bold;
}

.anhangVorschau {
    max-height: 100px;
    max-width: 100px;
}

#ticketTabelle .button {
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
}

#ticketAntwort input.button {
    border: 2px solid $color-primary;
    border-radius: 100px;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 50px;
    width: auto;
}

#ticketAntwort form {
    border-bottom: 1px solid #ccc;
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
}

/* Ticket-System Ende */
