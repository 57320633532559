/* ==========================================================================
01-01 MAIN SETTINGS
========================================================================== */

* {
    padding: 0;
    margin: 0;
}

.st0 {
    fill: #979797;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
.artikel_info .tabbed-container:focus {
    outline: none;
}

*:focus,
*.focus {
    outline: none;
}

body {
    font-family: Catamaran, Arial, sans-serif;
    font-size: 16px;
    overflow-x: hidden;
    color: #666;
}

body.noscroll {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
}

#shopLayer,
.wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

*::selection {
    background: $color-primary;
    color: #fff;
}

button {
    cursor: pointer;
}

p {
    margin: 0 0 20px 0;
}

.fixed {
    position: fixed;
    top: 0;
    z-index: 99999;
}

hr {
    border: none;
    border-top: 1px solid #ddd;
}

ul.list {
    padding: 30px 0 30px 40px;
}

blockquote {
    padding: 40px 40px 30px 40px;
    background: rgba(0, 0, 0, 0.03);
}

blockquote footer {
    text-align: right;
    margin: 20px 0 0 0;
}

a {
    text-decoration: none;
    color: $color-primary;
    transition: color 300ms ease-in-out;
}

a:hover {
    transition: color 300ms ease-in-out;
}

a.button {
    border: 2px solid $buttonborder;
    padding: 10px 20px;
    margin: 10px;
    display: inline-block;
    color: $helltext;
    cursor: pointer;
}

a#pp_link {
    background: $color-primary;
    border-color: $color-primary;
    padding: 10px 40px;
    color: #fff;
}

a.button:hover {
    border-color: $color-primary;
}

a.button.green {
    color: $color-primary;
    border: 2px solid $color-primary;
}

a.button img {
    width: 30px;
    height: 30px;
}

a.col.button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

h1 {
    color: $dunkeltext;
    padding: 0 0 10px 0;
    font-weight: 400;
    font-family: Crimson-Text, serif;
}

h2 {
    color: $dunkeltext;
    font-weight: 100;
    margin: 0;
    padding: 0 0 10px 0;
    font-family: Crimson-Text, serif;
}

h3 {
    color: $dunkeltext;
    font-weight: 400;
    margin: 0;
    padding: 0 0 10px 0;
    font-family: Crimson-Text, serif;
}

.center {
    text-align: center !important;
}

.font_big {
    font-size: 1.3rem;
    font-weight: 300;
}

.font_small {
    font-size: 0.8rem;
    font-weight: 300;
}

button {
    font-size: 1rem;
    padding: 10px 30px;
    border: 2px solid $buttonborder;
    font-weight: 700;
}

.center_flex {
    display: flex;
    justify-content: center;
}

.shop_tab_mitte {
    position: relative;
}

.no-border {
    border: none !important;
    margin-bottom: 0 !important;
}

div#cookieMeldung {
    display: none !important;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.PGerror img {
    display: none;
}

.PGerror {
    border: 2px solid $rot;
    background: $rot;
    padding: 20px;
    color: #fff;
}

#bankpf {
    position: relative !important;
    top: 0px !important;
    height: 0;
}

#bankpf .sprache {
    padding-top: 45px;
}

.nojs.error {
    border: 4px solid $rot;
    padding: 40px;
    display: block;
    max-width: 550px;
    margin: 40px auto;
    text-align: center;
}

div#mz_leer {
    text-align: center;
    font-size: 1.5rem;
    padding: 100px 20px;
    color: $helltext;
}

/* ==========================================================================
01-02 style input, select, radiobox & checkbox
========================================================================== */

/* remove standard-styles */
input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    font-size: 1em;
    width: 100%;
}

/* graceful degradation for ie8 */
input[type="checkbox"],
input[type="radio"] {
    width: auto;
    float: left;
    margin-right: 0.75em;
    background: transparent;
    border: none;
}

input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked),
input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
    background: transparent;
    position: relative;
    visibility: hidden;
    margin: 0;
    padding: 0;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
    cursor: pointer;
}

/* new-styles */

label {
    line-height: 34px;
    width: 90%;
    display: block;
}

#shopLayer input[type="text"],
#shopLayer input[type="password"],
#shopLayer input[type="email"],
#shopLayer input[type="tel"] {
    height: 35px !important;
    border: 1px solid #dddddd;
    padding: 15px;
    box-sizing: border-box;
    color: $helltext;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
}

input[type="text"]::placeholder {
    color: $buttonborder;
}

label input[type="checkbox"]:checked::before,
label input[type="checkbox"]:not(:checked)::before,
label input[type="radio"]:checked::before,
label input[type="radio"]:not(:checked)::before {
    content: " ";
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    top: 1px;
    border: 1px solid #bbb;
    background: white;
    margin: 2px 1em 4px -1px;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    visibility: visible;
}

label input[type="radio"]:checked::before,
label input[type="radio"]:not(:checked) + label::before {
    border-radius: 30px;
}

label input[type="checkbox"]:hover::before,
label input[type="radio"]:hover::before,
label:hover input::before {
    box-shadow: inset 0 0 0 2px white;
}

label input[type="checkbox"]:checked::before,
label input[type="radio"]:checked::before {
    background: $color-primary;
    box-shadow: inset 0 0 0 2px white;
}

#shopLayer input:focus,
#shopLayer select:focus {
    border: 2px solid $color-primary;
}

.text-align-center {
    text-align: center;
    padding: 0 10px;
}

.mobile {
    display: none;
}
