/* ==========================================================================
06. _BANNER
========================================================================== */

.start_banner_benefits {
    background: $dark;
    padding: 10px 20px;
    justify-content: space-around;
    overflow: hidden;
    position: relative;
    margin: 0 0 10px 0 !important;
}

.start_banner_benefits span.close_this {
    cursor: pointer;
    color: #61a395;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 0px;
    margin: 0;
    transform: none;

    &:after {
        display: inline-block;
        content: "\00d7"; /* This will render the 'X' */
    }
}

.start_banner_benefits .col {
    font-size: 0.8rem;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.start_banner_benefits .col:before {
    content: "";
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 0 12px 0 10px;
}

.start_banner_benefits .col span {
    margin: 0 10px 0 0;
}

.start_banner_benefits .col.free:before {
    background: url(#{$cdnPfad}/icon_pig.svg) 50% 50% no-repeat;
    //background: url(#{$cdnPfad}/b/benefit-free-shipping-xmas.svg) 50% 50% no-repeat;
    background-size: contain;
}

.start_banner_benefits .col.fast:before {
    background: url(#{$cdnPfad}/icon_fast-delivery.svg) 50% 50% no-repeat;
    //background: url(#{$cdnPfad}/b/benefit-fast-delivery-xmas.svg) 50% 50% no-repeat;
    background-size: contain;
}

.start_banner_benefits .col.long:before {
    background: url(#{$cdnPfad}/icon_exchange.svg) 50% 50% no-repeat;
    //background: url(#{$cdnPfad}/b/benefit-exchange-xmas.svg) 50% 50% no-repeat;
    background-size: contain;
}

.banner {
    .sale {
        background: #aaaeb1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 5rem;
        height: 100%;
        font-family: Crimson-Text, serif;
    }
}

.banner-logo.left-bottom {
    left: 30px;
    bottom: 20px;
}

.banner-logo.left-top {
    left: 30px;
    top: 20px;
}

.banner-logo.right-bottom {
    right: 30px;
    bottom: 20px;
}

.banner-logo.right-top {
    right: 30px;
    top: 20px;
}

.banner-text .show {
    display: none;
}

.banner-text {
    columns: 250px 2;
    column-gap: 30px;
    text-align: justify;
    line-height: 22px;
    font-size: 15px;
    color: #4a4a4a;
}

.text-winter,
.geschaeftsfuehrer {
    color: #4a4a4a;
}

.banner-header {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    color: #4a4a4a;
}

.banner-magazin {
    padding-bottom: 0;
}

.banner-header h3 {
    font-size: 40px;
    padding-bottom: 8px;
}

.banner.hersteller {
    cursor: default;
}

.banner.hersteller:hover {
    box-shadow: 0;
    transform: scale(1);
}

.banner.hersteller:hover img {
    box-shadow: none !important;
}

.banner.hersteller div {
    background: $dark;
    height: 100%;
    width: 100%;
    display: flex;
}

.banner.hersteller img {
    margin: 0 auto;
    width: 80%;
}

.banner {
    position: relative;
    width: 600px;
    transition:
        transform 200ms ease-in-out,
        box-shadow 200ms ease-in-out;
    transform: scale(1);
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
    transition: opacity 1500ms ease-in-out;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.banner a {
    border-top: 2px solid $color-primary;
    border-bottom: 2px solid $color-primary;
    transition: all 500ms ease-in-out;
    background: $dark;
    line-height: 0;

    &.border-none {
        border: none;
    }

    &.background-none {
        background: none;
    }
}

.banner:hover a {
    border-color: $color-primary;
    transition: all 500ms ease-in-out;
}

.banner:hover {
    transition:
        transform 200ms ease-in-out,
        box-shadow 200ms ease-in-out;
    transform: scale(1.02);
    transform-origin: center;
    position: relative;
    z-index: 10;
}

.banner a {
    position: relative;
    width: 100%;

    > span {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
        min-height: 50px;
        align-items: center;
        width: 100%;
        padding: 2rem;
        box-sizing: border-box;
        font-size: 2rem;
        line-height: 25px;
        color: #fff;
        font-weight: 400;
        font-family: Crimson-Text, serif;

        &.primary {
            min-height: 100px;
            font-size: 3rem;
            line-height: 110%;
            padding: 2rem;
        }
    }
}

.banner h2 {
    padding: 10px 10px 20px 20px;
    color: #fff;
    font-weight: 200;
    font-size: 3rem;
    max-width: 70%;
}

.banner-headline {
    position: absolute;
    bottom: 4px;
    width: 100%;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

.banner img {
    max-width: 100%;
    height: auto;
}

.banner:hover img {
    transition: box-shadow 200ms ease-in-out;

    /* box-shadow: 0px 0px 23px rgba(0,0,0,0.1); */
}

.banner > a > div {
    flex: 1;
    min-height: 329px;
    background-size: cover !important;
    display: flex;
    flex-direction: column-reverse;
    line-height: initial;
}

.banner div div {
    color: #fff;
    padding: 20px;
}

.banner div div h3 {
    font-size: 2rem;
    font-weight: 400;
    position: relative;
    margin: 0 0 0 -1px;
    border: none;
    text-align: left;
    padding: 0;
}

.banner div div h3:after {
    content: "";
    width: 30px;
    height: 30px;
    background: url(#{$cdnPfad}/icon_arrow.svg) 50% 50% no-repeat;
    background-size: contain;
    display: inline-block;
    margin: 0px 0px -5px -10px;
    opacity: 0;
    position: absolute;
    bottom: 10px;
    transition: all 200ms ease-in-out;
}

.banner:hover div div h3:after {
    margin: 0 0 -5px 10px;
    opacity: 1;
    transition: all 200ms ease-in-out;
}

.banner {
    opacity: 1;
}

img.banner-img {
    width: 100%;
    height: 100%;
}

.banner-logo {
    position: absolute;
    width: 20%;
    padding: 20px;
}

.banner-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 0 !important;
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.2) 100%) !important;
}

@media screen and (max-width: 576px) {
    .start_banner_benefits {
        .col:before {
            display: none;
        }
    }

    .banner a {
        position: relative;
        width: 100%;
    }

    .banner div div h3 {
        font-size: 1.4rem;
    }
}

/*------------------------------------ Banner größen ---------------------------------------------------------------------------*/

.b1 {
    flex-basis: 25%;
    max-width: 25%;
}

.b2 {
    flex-basis: 50%;
    max-width: 50%;
}

.b3 {
    flex-basis: 75%;
    max-width: 75%;
}

/*** Sale-Banner animiert auf Startseite ***/

.headline--jump {
    .char {
        --name: jump;
        --dur: 800ms;
        --del: calc(var(--char-index) * 0.075s);
        --tf: cubic-bezier(0.165, 0.44, 0.64, 1);
    }

    @keyframes jump {
        20% {
            transform: translateY(2%) scaleY(0.9);
        }

        40% {
            transform: translateY(-100%) scaleY(1.2);
        }

        50% {
            transform: translateY(10%) scaleY(0.8);
        }

        70% {
            transform: translateY(-5%) scaleY(1);
        }

        80%,
        100% {
            transform: translateY(0) scaleY(1);
        }
    }
}

@-webkit-keyframes bounce {
    100% {
        top: -20px;
        text-shadow:
            0 1px 0 #ccc,
            0 2px 0 #ccc,
            0 3px 0 #ccc,
            0 4px 0 #ccc,
            0 5px 0 #ccc,
            0 6px 0 #ccc,
            0 7px 0 #ccc,
            0 8px 0 #ccc,
            0 9px 0 #ccc,
            0 50px 25px rgba(0, 0, 0, 0.2);
    }
}

@keyframes breathe {
    0% {
        font-variation-settings:
            "wght" 100,
            "wdth" 85;
        font-size: 5vw;
    }

    60% {
        font-variation-settings:
            "wght" 700,
            "wdth" 100;
        font-size: 8vw;
    }

    100% {
        font-variation-settings:
            "wght" 100,
            "wdth" 85;
        font-size: 5vw;
    }
}

@media (max-width: 576px) {
    #banner-animated {
        min-height: 250px;

        > span {
            font-size: 100px;
        }
    }
}

@media (max-width: 80em) {
    .b1 {
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }

    .b2 {
        flex-basis: 66.66667%;
        max-width: 66.66667%;
    }

    .b3 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media (max-width: 64em) {
    .b1 {
        flex-basis: 50%;
        max-width: 50%;
    }

    .b2 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .banner a {
        > span {
            &.primary {
                font-size: 2rem;
            }
        }
    }
}
