/* ==========================================================================
05. _FILTER
========================================================================== */

.li_sort_see_nrauf,
.li_sort_see_nrab,
.li_sort_see_az,
.li_sort_see_za {
    display: none;
}

#dd_farb {
    .see_li {
        > .see_multi_label {
            color: #ffffff;
        }
    }

    .see_li.see_fa_1,
    .see_li.see_fa_4,
    .see_li.see_fa_12 {
        > .see_multi_label {
            color: #6e6e6e;
        }
    }
}

.see_li_fa label input:after {
    content: "";
    display: inline-block;
    width: 200px;
    height: 28px;
    visibility: visible;
    margin-right: 10px;
    position: absolute;
    left: -5px;
    top: 0px;
    z-index: -1;
}

.see_li_fa label {
    color: #ccc;
}

/* Microsoft Edge Browser 12+ (All) - @supports method */

@supports (-ms-ime-align: auto) {
    .see_li_fa label {
        color: $dunkeltext;
    }
}

.see_li_fa label input#see_fa_15:after {
    background: url(#{$cdnPfad}/camouflage.svg) 0 0 no-repeat;
    background-size: cover;
}

.see_li_fa label input#see_fa_14:after {
    background: orange;
}

.see_li_fa label input#see_fa_13:after {
    background: #666;
}

.see_li_fa label input#see_fa_12:after {
    background: #fff;
    outline: 1px solid #ccc;
}

.see_li_fa label input#see_fa_11:after {
    background: black;
    color: #fff;
}

.see_li_fa label input#see_fa_10:after {
    background: $rot;
}

.see_li_fa label input#see_fa_9:after {
    background: #ff54d7;
}

.see_li_fa label input#see_fa_8:after {
    background: url(#{$cdnPfad}/multi_color.svg) 0 0 no-repeat;
    background-size: cover;
}

.see_li_fa label input#see_fa_7:after {
    background: #9a00bb;
}

.see_li_fa label input#see_fa_6:after {
    background: $color-primary;
}

.see_li_fa label input#see_fa_5:after {
    background: #999;
}

.see_li_fa label input#see_fa_4:after {
    background: #ffe500;
}

.see_li_fa label input#see_fa_3:after {
    background: #a27b00;
}

.see_li_fa label input#see_fa_2:after {
    background: #006ed4;
}

.see_li_fa label input#see_fa_1:after {
    background: beige;
}

.tab_see {
    background: $dark;
    padding: 0 10px;
    margin: 0 0 20px 0;

    /*max-height:400px;*/
    position: relative;
    z-index: 12;
    transition: all 300ms ease-in-out;
}

#bread:hover #filter_anzeigen div.toggle_filter {
    background: $color-primary;
}

#bread {
    cursor: pointer;
    color: $helltext;
}

.tab_see.filter_toggle {
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease-in-out;
}

.tab_see.filter_toggle .see_alle_dd li {
    opacity: 0;
    transition: all 300ms ease-in-out;
}

.tab_see .see_alle_dd li {
    opacity: 1;
    transition: all 300ms ease-in-out;
}

*[data-filter="hide_filter"] .tab_see {
    display: none;
}

figure#filter_anzeigen {
    display: flex;
    align-items: center;
}

#filter_anzeigen div.toggle_filter {
    transition: background 0.5s;
    margin-left: 10px;
    width: 33px;
    height: 20px;
    box-sizing: border-box;
    padding: 2px;
    background: $color-primary;
    float: right;
}

#filter_anzeigen div:before {
    transition: margin 0.5s;
    content: " ";
    display: block;
    float: left;
    height: 16px;
    width: 16px;
    background: white;
}

#filter_anzeigen.filter_toggle div.toggle_filter {
    background: $helltext;
}

#filter_anzeigen.filter_toggle div:before {
    float: right;
}

.see_ueberschrift {
    display: none;
}

form[name="dd_aug_form"] {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    flex-wrap: wrap;
}

form[name="dd_aug_form"] ul:nth-of-type(1) li {
    z-index: 23;
}

form[name="dd_aug_form"] ul:nth-of-type(2) li {
    z-index: 22;
}

form[name="dd_aug_form"] ul:nth-of-type(3) li {
    z-index: 21;
}

form[name="dd_aug_form"] ul:nth-of-type(4) li {
    z-index: 20;
}

form[name="dd_aug_form"] ul:nth-of-type(5) li {
    z-index: 19;
}

form[name="dd_aug_form"] ul:nth-of-type(6) li {
    z-index: 18;
}

form[name="dd_aug_form"] ul:nth-of-type(7) li {
    z-index: 17;
}

form[name="dd_aug_form"] ul:nth-of-type(8) li {
    z-index: 16;
}

form[name="dd_aug_form"] ul:nth-of-type(9) li {
    z-index: 15;
}

form[name="dd_aug_form"] ul:nth-of-type(10) li {
    z-index: 14;
}

form[name="dd_aug_form"] a {
    color: $helltext;
}

form[name="dd_aug_form"] ul {
    list-style: none;
    cursor: pointer;
}

form[name="dd_aug_form"] > ul {
    margin: 5px;
    position: relative;
    flex: 1 0 calc(33% - 10px);
    min-height: 42px;
}

form[data-anzahl-filter="4"] > ul {
    flex: 1 0 calc(25% - 10px);
}

form[name="dd_aug_form"] > ul > li {
    background: #fff;
    position: relative;
    z-index: 10;
    border: 1px solid $hellgrau;
    max-height: 42px;
    overflow: hidden;
    box-shadow: none;
    transition:
        max-height 300ms ease-in-out,
        box-shadow 200ms ease-in-out,
        border-color 200ms ease-in-out;
}

form[name="dd_aug_form"] > ul > li.active {
    max-height: 351px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-color: $color-primary;
    transition:
        max-height 300ms ease-in-out,
        box-shadow 200ms ease-in-out,
        border-color 200ms ease-in-out;
    position: absolute;
    width: 100%;
    padding-bottom: 10px;
}

form[name="dd_aug_form"] > ul .dd_list {
    display: none;
}

.see_multi_label {
    float: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 130%;
    padding: 5px 0;
}

form[name="dd_aug_form"] > ul li.active .dd_list {
    position: relative;
    z-index: 1;
    padding: 35px 10px 0 10px;
    box-sizing: border-box;
    margin: -24px 0 0 0;
}

.see_alle_dd {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
}

.se_li.se_li_such {
    height: 45px !important;
    margin: 10px 10px 0 0;
}

.se_li.se_li_such input {
    width: 98%;
    background: white url(#{$cdnPfad}/icon_search.svg) no-repeat 93% 50%;
    background-size: 7%;
}

#dd_zk1 .se_li_such {
    display: none;
}

form div[class*="_kopf"] {
    position: relative;
    border-radius: 200px;
    height: 40px;
    z-index: 2;
    background: #fff;
}

form div[class*="_top_kopf"] {
    border: none;
    background: transparent;
}

form div[class*="_kopf"] a {
    width: 100%;
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 18px;
    box-sizing: border-box;
}

form[name="dd_aug_form"] ul div[class*="_kopf"]:after {
    content: " ";
    display: block;
    background: url(#{$cdnPfad}/dropdown-arrow.svg) no-repeat (50% / contain);
    height: 24px;
    width: 24px;
    position: absolute;
    top: 10px;
    right: 12px;
    transition: transform 0.5s;
}

form[name="dd_aug_form"] ul li.active div[class*="_kopf"]:after {
    transform: rotate(180deg);
    transition: transform 0.5s;
}

.dd_list ul {
    max-height: 245px;
    overflow-y: scroll;
}

.see_li.active label:before {
    background: $color-primary url(#{$cdnPfad}/dropdown-arrow.svg) no-repeat 50% / 10px 10px;
}

.see_li:hover {
    background: transparent;
    cursor: pointer;
}

.dd_list ul::-webkit-scrollbar {
    width: 8px;
}

.dd_list ul::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #eee;
}

.dd_list ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-primary;
}

.see_multi_submit {
    height: 35px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    font-family: Catamaran, Arial, sans-serif;
    color: #9b9b9b;
    margin-top: 10px;
}

.see_multi_submit:hover {
    background: $color-primary;
    color: #fff;
}

.see_li_fa_pic {
    width: 55px;
    height: 30px;
    border-radius: 20px;
    border: 1px solid #fff;
    margin-left: -43px;
}

.see_li_fa_pic[style*="background-color:#FFFFFF"] {
    border: 1px solid $hellgrau;
}

.see_multi_loesch_buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 10px 0;
    padding: 0;
}

.see_multi_loesch_txt,
.see_multi_loesch_form {
    float: none;
    margin: 5px 0;
}

.see_multi_loesch_txt {
    margin: 0 10px 0 6px;
}

.see_multi_loesch {
    padding: 7px 40px 7px 13px;
    background: url(#{$cdnPfad}/icon_close.svg) no-repeat;
    background-size: 10px 10px;
    background-position: right 12px center;
    margin: 0 10px 0 0;
    font-weight: 100;
}

.see_multi_loesch:hover {
    border-color: $rot;
    color: $rot;
    background: url(#{$cdnPfad}/icon_close_red.svg) no-repeat;
    background-size: 10px 10px;
    background-position: right 12px center;
}

.see_multi_loesch.see_multi_loesch_alle {
    background-color: transparent !important;
    display: none;
}

@media all and (max-width: 576px) {
    form[name="dd_aug_form"] > ul > li.active {
        position: relative;
    }
}
