/* ==========================================================================
15. Merkliste/Favoriten
========================================================================== */

#shopLayer[data-pp*="perart"] .tab_see,
#shopLayer[data-pp*="perart"] #bread {
    display: none !important;
}

#shopLayer[data-pp*="perart"] div#blaettern_oben {
}

#mz_leer + .shop_tab_mitte .top_bread-sort {
    display: none;
}

#shopLayer[data-pp*="perart"] ul.list_se {
    margin-top: 50px;
}

#shopLayer[data-pp*="perart"] header .fav .st1,
header .fav:hover .st1,
header .warenkorb:hover .st1,
header .login:hover .st1,
#shopLayer[data-pp*="wk"] header .warenkorb .st1 {
    stroke: $color-primary;
}

#shopLayer[data-pp*="perart"] h1 {
    padding: 20px;
    text-align: center;
}
