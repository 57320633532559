/* ==========================================================================
04. SUCHERGEBNIS & ARTIKEL
========================================================================== */

ul.se li {
    list-style-type: none;
}

.artikel {
    background: #fff;
    transition: all 100ms ease-in-out;
    overflow: visible;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    line-height: 0;
    list-style-type: none;
}

.artikel > div {
    border: 2px solid transparent;
    border-left: none;
    border-right: none;
    overflow: hidden;
    line-height: 130%;
    width: 100%;
    position: relative;
    background: #f8f8f8;
    border-bottom: 2px solid #ececec;
}

.artikel:hover figure {
    max-width: 284px;
    margin: 0 auto;
}

.artikel:hover,
.artikel.active {
    z-index: 10;
    max-height: 399px;
}

.artikel:hover > div {
    border: 2px solid $color-primary;
    border-right: none;
    border-left: none;
    width: 400px;
    position: relative;
    z-index: 40;
    transition:
        border 250ms ease-in-out,
        box-shadow 200ms ease-in-out;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

    .se_farben {
        display: flex;
    }
}

.artikel figure > div:before {
    content: "";
    display: block;
    background-image: var(--bg-model);
    background-size: cover;
    opacity: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.artikel:hover figure > div:before {
    opacity: 0;
    content: "";
    display: block;
    background-image: var(--bg-model);
    background-size: cover;
    height: 260px;
    width: 284px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

figure > div {
    display: flex;
    width: 100%;
}

.artikel .azu {
    transition: all 200ms ease-in-out;
    width: 34px;
    position: absolute;
    top: 60px;
    left: -2px;
    z-index: 2;
    padding: 0 10px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    transform: translateX(-120%);
    opacity: 0;
}

.azu svg {
    margin: 0 0 10px 0;
    transform: scale(0.9);
}

.artikel .se_fav {
    //transition: all 200ms ease-in-out;
    position: absolute;
    top: 60px;
    right: -92px;
    z-index: 100;
    width: 33px;
    height: 32px;
    opacity: 0;
    cursor: pointer;
    background: url(#{$cdnPfad}/icon_fav.svg) 0 0 no-repeat;
}

.artikel .se_neu + .se_fav {
    top: 60px;
}

.artikel:hover .se_fav {
    right: 7px;
    transition: all 200ms ease-in-out;
    transition-delay: 200s;
}

.artikel .se_fav:hover {
    background: url(#{$cdnPfad}/icon_fav_rot.svg) 0 0 no-repeat;
}

.artikel .se_fav.gemerkt {
    background: url(#{$cdnPfad}/icon_fav_rot.svg) 0 0 no-repeat;
    opacity: 1 !important;
    transform: translateX(0%);
    right: 12px;
}

div[data-farbe="5"] {
    border: 1px solid #ddd !important;
}

.artikel .se_fav:after {
    content: "";
    background: url(#{$cdnPfad}/icon_fav_rot_full.svg) 50% 50% no-repeat;
    width: 30px;
    height: 30px;
    display: block;
    transform: scale(0);
    transition: transform 300ms ease-in-out;
}

.artikel .se_fav.gemerkt:after {
    transform: scale(1);
    transition: transform 300ms ease-in-out;
}

.artikel .se_fav svg {
    margin: 4px;
    display: block;
    overflow: visible;
}

.artikel .se_fav.gemerkt + span.se_neu {
    display: none;
}

.artikel:hover .se_fav,
.artikel:hover .azu,
.artikel.active .azu,
.artikel.active .se_fav {
    transform: translateX(0);
    transition-delay: 200ms;
}

span.se_neu {
    position: absolute;
    right: 0;
    top: 20px;
    background: $color-primary;
    padding: 5px 8px;
    z-index: 10;
    color: #fff;
    z-index: 1001;
}

span.se_diff {
    background: $rot;
    transition: right 400ms ease-in-out;
}

.artikel:hover span.se_neu,
.artikel:hover span.se_diff {
    right: 0;
    transition: all 200ms ease-in-out;
    transition-delay: 200ms;
}

.artikel .se_farben {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    opacity: 0;
    z-index: 1000;
    transform: translateY(-10px);
    transition: all 300ms ease-in-out;
    position: absolute;
}

.lief_gr_farbe1_k {
    float: none;
}

.se_farben div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 14px 0;
}

.artikel .se_farben .lief_gr_farbe1_k {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin: 4px;
    border: 1px solid #fff;
    transform: scale(0.6);
    opacity: 0;
    transform: translateY(20px);
    transition: all 300ms ease-in-out;
}

.artikel .se_farben .lief_gr_farbe1_k[style*="mehrfarbig"] {
    border: 0;
    background-size: cover;
}

.artikel:hover .se_farben .lief_gr_farbe1_k,
.artikel.active .se_farben .lief_gr_farbe1_k,
.artikel:hover .se_farben,
.artikel.active .se_farben {
    transform: scale(1);
    opacity: 1;
    transform: translateY(0);
    transition: all 300ms ease-in-out;
}

.artikel:hover .se_unterteil {
    margin-bottom: 53px;
}

.artikel .se_farben .lief_gr_farbe1_k.active {
    /*   width:30px;
    height:30px; */
}

.artikel .se_farben .lief_gr_farbe1_k[style="background: #FFFFFF;"] {
    border: 1px solid #ccc;
}

.artikel:hover .azu,
.artikel:hover .se_fav,
.artikel.active .azu,
.artikel.active .se_fav {
    opacity: 0.5;
}

.artikel:hover .se_fav:hover path {
    stroke: #d0021b;
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.artikel .se_fav:hover path {
    /*   transform: scale(1.3);
    transform-origin: center;
    transition: all 200ms ease-in-out; */
    animation: pulse 600ms infinite;
    transform-origin: center;
}

/* .artikel:hover .se_fav path {
transition: all 10ms ease-in-out;
}
*/
@-webkit-keyframes pulse {
    0% {
        transform: scale(0.9);
    }

    70% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0.9);
    }
}

.artikel .new,
.artikel .sale {
    text-transform: uppercase;
    position: absolute;
    top: 7px;
    right: 0;
    padding: 3px 6px;
    background: $color-primary;
    color: #fff;
    transition: all 200ms ease-in-out;
}

.artikel .sale,
.aa-proz {
    background: #d84d4d;
}

.artikel .sale::after {
    content: attr(data-sale);
}

.artikel:hover .new,
.artikel:hover .sale {
    transform: translateX(110%);
}

.artikel #aa_rating {
    position: absolute;
    top: -30px;
    z-index: 2;
    width: 100px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

span.rating_tab {
    line-height: 0;
}

.artikel #aa_rating .rat_stars i,
.artikel #aa_rating .rat_stars b,
.artikel #aa_rating .rat_stars u {
    width: 20px;
    height: 20px;
}

span.rating_tab .rat_stars i,
span.rating_tab .rat_stars b,
span.rating_tab .rat_stars u {
    width: 20px;
    height: 20px;
}

.artikel:hover #aa_rating {
    top: 10px;
    transition: all 200ms ease-in-out;
    transition-delay: 400ms;
}

.artikel .se_name {
    text-align: center;
    font-size: 1.3rem;
    margin: 8px auto;
    height: 55px;
    font-family: Crimson-Text, serif;
    color: $color-primary;
    line-height: 105%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 278px;

    span {
        padding: 0 5px;
    }
}

.artikel .se_name div {
    color: $helltext;
    font-size: 0.83rem;
    margin: 3px 0 0 0;
}

.lief_gr_farbe1_k:hover {
    transform: scale(1.4) !important;
}

.artikel .se_preis {
    text-align: center;
    font-size: 1.5rem;
    margin: 10px 0 10px 0;
    font-weight: 400;
    color: #777;
}

.artikel .se_preis span.red {
    color: $rot;
}

.artikel .se_preis span strike {
    color: $buttonborder;
    font-size: 1.2rem;
}

.artikel a {
    text-decoration: none;
    color: $dunkeltext;
    font-weight: 300;
}

ul.list_se {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
}

ul.list_se li:nth-of-type(2) {
    animation-delay: 50ms;
}

ul.list_se li:nth-of-type(3) {
    animation-delay: 100ms;
}

ul.list_se li:nth-of-type(4) {
    animation-delay: 150ms;
}

ul.list_se li:nth-of-type(5) {
    animation-delay: 200ms;
}

ul.list_se li:nth-of-type(6) {
    animation-delay: 250ms;
}

ul.list_se li:nth-of-type(7) {
    animation-delay: 300ms;
}

ul.list_se li:nth-of-type(8) {
    animation-delay: 350ms;
}

.artikel img.artikel-img {
    max-width: 100%;
    height: auto;
    margin: 0 auto 15px auto;
    display: block;
    max-height: 204px;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    transition: all 400ms ease-in-out;
    position: relative;
    align-self: center;
}

.artikel figure:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: $dark;
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
}

.artikel .info {
    position: relative;
    z-index: 10;
    position: absolute;
    top: 11px;
    right: 0;
}

.artikel figure::after {
    content: "";
    width: 300px;
    height: 50px;
    display: block;
    background: rgba(0, 0, 0, 1);
    position: absolute;
    bottom: 14px;
    z-index: 2;
    opacity: 0.1;
    background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 1) 0%, rgba(102, 102, 102, 0) 40%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(40%, rgba(102, 102, 102, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 1) 0%, rgba(102, 102, 102, 0) 40%, rgba(255, 255, 255, 0) 100%);
    background: -o-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 1) 0%, rgba(102, 102, 102, 0) 40%, rgba(255, 255, 255, 0) 100%);
    background: -ms-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 1) 0%, rgba(102, 102, 102, 0) 40%, rgba(255, 255, 255, 0) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 1) 0%, rgba(102, 102, 102, 0) 40%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=1 );
    transition: all 700ms ease-in-out;
}

ul.cross_se {
    max-height: 400px;
    padding: 20px 20px 0 20px;
}

#aa_cross .artikel:hover .se_fav {
    right: 9px;
}

.artikel figure {
    /* outline:2px solid red; */
    position: relative;
    min-height: 260px;
    display: flex;
    display: -ms-flexbox !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: #ffffff;
    padding: 0 10px;
}

.wrapper_artikel-detail span.breadcrum_arrow {
    opacity: 0.5;
}

#pacerGoOnButton {
    display: none;
    order: 99999999;
    width: 100%;
}

#pacerGoOnButton .button {
    min-width: 390px;
    min-height: 60px;
    margin: 50px auto 70px auto;
    display: block;
    background: $color-primary;
    border: none;
    color: #fff;
    font-weight: 200;
    transition: all 200ms ease-in-out;
    font-size: 1.2rem;
}

#blaettern_oben #pag_se_all{
    display: none;
}

#pag_se_all{
    width: 100%;
    
    #pag_se {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        font-size: 1.5rem;
        margin: 4rem 0 0;
    
        a {
            display: flex;
            padding: 10px 40px;
    
            &:hover svg path {
                fill: $color-primary;
            }
    
            svg path {
                fill: $color-primary;
            }
        }
    
        .pag-left{
            display: flex;
    
            a {
                display: block;
                padding: 10px 20px !important;
            }
        }
        
        #pag_1.seite_1 {
            display: none;
        }
    }
}

@media (max-width: 395px) {
    #pacerGoOnButton .button {
        min-width: initial;
    }
}

#pacerGoOnButton .button:hover {
    background: $color-primary;
    transition: all 200ms ease-in-out;
}

@keyframes levitate {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    100% {
        -webkit-transform: translate(0, 0.3rem);
        transform: translate(0, 0.3rem);
    }
}

@media (min-width: 577px) {
    .artikel > div {
        min-height: 379px;
    }
}

@media (max-width: 760px) {
    .se_unterteil {
        margin-bottom: 23px !important;
    }

    .artikel:hover > div {
        border: 2px solid #61a395;
        border-right: none;
        border-left: none;
        width: auto;
        position: relative;
        z-index: 40;
        box-shadow: none;
    }
}

@media (max-width: 576px) {
    .artikel figure::after {
        bottom: -10px;
    }
}
