/* ==========================================================================
14. Service Seiten/Menü/Login
========================================================================== */

.grid {
    &.bestelluebersicht,
    &.newsletter-anmeldung {
        align-items: baseline;
    }
}

.kontaktformular button {
    float: right;
}

.box.kontaktformular select {
    min-height: 70px;
    height: 35px;
    background: #fff;
    width: 100%;
    font-size: 1rem;
}

.kontaktformular textarea {
    font-size: 1rem;
    color: #333;
    border: 1px solid #dddddd;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    resize: none;
}

img.img_left {
    width: 130px;
    float: left;
    margin: 0 30px 0 0;
}

img.img_right {
    width: 130px;
    float: right;
    margin: 0 30px 0 0;
}

.zahlungsarten .col {
    display: flex;
    justify-content: center;
    max-width: initial;
}

.box.zahlungsart {
    color: $color-primary;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 900;
    display: flex;
    align-items: center;
}

.groessentabelle td {
    padding: 10px 0;
    border-right: 1px solid #ddd;
    text-align: center;
}

.groessentabelle h2 {
    position: sticky;
    left: 0;
    margin: 0 0 10px 0;
}

.groessentabelle table {
    width: 100%;
    min-width: 650px;
    margin: 0 0 30px 0;
}

.groessentabelle table th {
    text-align: left;
    position: sticky;
    left: 0;
    background: white;
}

#men_6 li.active a.alvl_1 {
    color: #000;
}

ul.men_FAQ_SUB {
    padding: 0 0 0 10px;
}

ul.men_FAQ_SUB li:first-child a {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

nav ul li.hasChildren div {
    height: 0;
    overflow: hidden;
}

nav ul li.hasChildren.active div {
    height: auto;
    overflow: hidden;
    transition: all 400ms ease-in-out;
}

[data-pp*="htm"] .BreadcrumbList {
    margin: 15px 0 20px 0;
}

#men_6 {
    position: sticky;
    top: 20px;
}

#men_6,
#men_10 {
    background: $dark;
    padding: 20px 30px;
}

#men_6 ul,
#men_10 ul {
    list-style-type: none;
    cursor: pointer;
}

#men_6 a,
#men_10 a {
    display: block;
    line-height: 19px;
    padding: 4px 0;
    margin: 10px 0;
}

.icon_service {
    background: $dark;
    padding: 30%;
    height: 0;
    background-position: 50%;
}

.icon_service.free {
    background-image: url(#{$cdnPfad}/icon_pig.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.icon_service.umtausch {
    background-image: url(#{$cdnPfad}/icon_exchange.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.icon_service.dhl {
    background-image: url(#{$cdnPfad}/icon_fast-delivery.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

#service_wrapper .bestue_headline + .wk_zk_div {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in;
}

#service_wrapper .bestue_headline.active + .wk_zk_div {
    height: auto;
    max-height: 1500px;
    transition: max-height 300ms ease-in;
}

.wk_zk_div + h2#keineBestellungDa {
    display: none;
}

.bestue_headline {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: $dark;
    margin: 0 0 2px 0;
    cursor: pointer;
}

.bestue_headline.active {
}

.bestue_headline:hover {
    color: $color-primary;
}

#service_wrapper h1 {
    margin: 0;
}

.tab_k:empty {
    display: none;
}

[data-pp*="bue"] #service_wrapper h1,
[data-pp*="bue"] .tab_k {
    color: #4a4a4a;
    margin: 10px 0 2px 0;
    border-bottom: 1px solid #ddd;
    padding: 0 0 10px 0;
    font-weight: 400;
    font-size: 1.5rem;
    display: block;
}

.bestue_headline:after {
    content: "";
    width: 30px;
    height: 20px;
    background: url(#{$cdnPfad}/icon_breadcrum_arrow.svg) 50% 50% no-repeat;
    background-size: contain;
    transform: rotate(90deg);
    transition: all 300ms ease-in;
}

.bestue_headline:hover:after {
    background: url(#{$cdnPfad}/icon_breadcrum_arrow_g.svg) 50% 50% no-repeat;
    background-size: contain;
}

.bestue_headline.active:after {
    transform: rotate(-90deg);
    transition: all 300ms ease-in;
}

.bestue_headline.active {
    background: #eee;
}

.bestue_headline span {
    flex: 1;
    margin: 0 0 0 43px;
}

.wk_zahlart_versand > .col > div {
    background: $dark;
    padding: 15px 15px 0;
}

.wk_zahlart_versand .wk_versand .col > span {
    display: none;
}

.wk_zahlart {
    margin-right: 2px;
}

.wk_zahlart_versand h3 {
    font-weight: 100;
    margin: 0 0 4px 0;
    border-bottom: 1px solid #ddd;
}

.wk_zahlart_versand p {
    margin: 10px 0 0 0;
}

.wk_versand img {
    max-height: 30px;
}

.wk_zahlart_versand.grid-2-noGutter-equalHeight {
    margin: 0 0 17px 0;
}

.wk_zahlart_versand + .bestell-artikel {
    position: relative;
}

.bestell-artikel .bestell_bild {
    justify-content: center;
}

.wk_zahlart_versand + .bestell-artikel .bestell_artikel:before,
.wk_zahlart_versand + .bestell-artikel .bestell_anzahl:before,
.wk_zahlart_versand + .bestell-artikel .bestell_preis:before,
.wk_zahlart_versand + .bestell-artikel .bestell_gespreis:before {
    position: absolute;
    top: 10px;
    font-size: 0.7rem;
    color: #9b9b9b;
    display: flex;
    justify-content: center;
}

.bestell-artikel .bestell_artikel,
.bestell-artikel .bestell_anzahl,
.bestell-artikel .bestell_preis,
.bestell-artikel .bestell_gespreis {
    display: flex;
    justify-content: center;
}

.bestell-artikel .bestell_artikel {
    justify-content: left;
}

div.bestell-artikel {
    height: 100px;
}

.wk_zahlart_versand + .bestell-artikel .bestell_artikel:before {
    content: $artikel;
}

.wk_zahlart_versand + .bestell-artikel .bestell_anzahl:before {
    content: $anzahl;
}

.wk_zahlart_versand + .bestell-artikel .bestell_preis:before {
    content: $preis;
}

.wk_zahlart_versand + .bestell-artikel .bestell_gespreis:before {
    content: $gesamt;
}

.bestell-artikel {
    height: 60px;
    background: $dark;
    margin: 0 0 2px 0 !important;
}

.bestell-artikel img.wk_pic {
    max-width: 60px;
}

.bestell-artikel [class*="col-"] {
    display: flex;
    align-items: center;
}

[data-pp*="bue"] .tab_k {
    display: none;
}

.bestell_img {
    position: relative;
    margin: 0 0 0 7px;
}

.bestell_img:before {
    content: "";
    position: absolute;
    height: 60px;
    width: 100%;
    background: $dark;
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    line-height: 0;
    font-size: 0;
    padding: 0;
}

.bestell_preis {
    text-align: right;
}

.bestell-artikel [class*="col-"].bestell_preis,
.bestell-artikel [class*="col-"].bestell_gespreis {
    justify-content: flex-end;
}

.bestell-artikel [class*="col-"].bestell_gespreis {
    padding: 0 10px 0 0;
}

#service_komplett span.name_in_wk {
    color: $dunkeltext;
}

.zk_wk_summe {
    display: flex;
    background: rgba(0, 0, 0, 0.03);
    margin: 0 0 5px 0;
    padding: 30px 10px;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    border-bottom: 2px solid #333;
}

.zk_gsum span:first-child,
.zk_vers span:first-child {
    color: $helltext;
    font-size: 0.8rem;
}

.zk_gsum span:last-child,
zk_vers span:last-child {
    font-weight: 700;
}

#servicehead {
    padding: 0 10px;
}

.faq_rahmen {
    .faq {
        margin: 0 0 30px 0;
    }

    .faq_title {
        font-weight: 700;
        margin: 0 0 10px 0;
        color: $color-primary;
    }
}

@media (max-width: 1024px) {
    .groessentabelle {
        overflow-x: auto;
    }
}
