/* ==========================================================================
XX. Browser weichen
========================================================================== */

/* _only edge----------------------------------------------------------------------------------------------------------------------------------- */

@supports (-ms-ime-align: auto) {
    input[type="checkbox"]:checked,
    input[type="checkbox"]:not(:checked),
    input[type="radio"]:checked,
    input[type="radio"]:not(:checked) {
        background: transparent;
        position: relative;
        visibility: visible;
        margin: 0 10px 0 0;
        padding: 0;
    }

    table.wk_tab_summe tr:nth-of-type(4) span b {
        display: block;
    }

    span.b.zk_gsum b b {
        line-height: 0;
        width: 100%;
        white-space: nowrap;
        bottom: -8px;
    }

    body span.b.zk_gsum {
        display: block;
    }

    body .artikel .se_fav {
        top: 93px;
    }
}

/* IE 11 Hacks */
@media all and (-ms-high-contrast: none) and (max-width: 1024px) {
    .wk_tab_summe #wk_bottom .bezahlmethoden {
        display: table;
    }
}

@media screen\0 {
    /*IE 11 DUMM-DUMM-Ecke*/

    table.wk_tab_summe tr:nth-of-type(4) span b {
        display: block;
    }

    .aktive_felder button[name="gut"] img {
        display: none;
    }

    .aktive_felder button[name="gut"] {
        background: url(#{$cdnPfad}/icon_breadcrum_arrow.svg) $hellgrau no-repeat 50% 50%;
        background-size: 20px;
    }

    body .tabbed-container {
        position: static;
        height: auto;
        overflow: visible;
    }

    .artikel_info.artikel-detail_left .flex {
        display: block;
    }

    body .artikel figure {
        display: block;
    }

    body .artikel .se_fav {
        top: 93px;
    }

    body .artikel figure:after {
        width: 0;
    }

    form[name="dd_aug_form"] > ul {
        flex-grow: 1;
    }

    body .see_multi_label {
        white-space: nowrap;
    }

    body form[name="dd_aug_form"] > ul > li.active {
        position: static;
    }

    body .artikel #aa_rating {
        left: 50%;
        transform: translate(-50%, 0px);
    }

    body .wk_zk_div > table > tbody {
        display: table-header-group;
    }

    body table.wk > tbody > tr,
    table.wk_zk > tbody > tr {
        display: table-row;
    }

    span.b.zk_gsum b b {
        line-height: 0;
        width: 100%;
        white-space: nowrap;
        bottom: -8px;
    }

    body span.b.zk_gsum {
        display: block;
    }

    body table#wk tr td form table:nth-of-type(2) tbody tr td table tbody tr {
        display: table-row;
    }

    body .button {
        line-height: 0;
        padding: 20px 30px;
    }

    body #shopLayer input[type="text"],
    body #shopLayer input[type="password"],
    body #shopLayer input[type="email"],
    body #shopLayer input[type="tel"] {
        line-height: 0;
    }

    body .div_login tr td {
        height: auto;
    }

    body .div_login {
        max-width: 1200px;
    }

    body .za_dk-div {
        width: 35%;
    }

    body .footer .svg_container {
        height: 40px;
    }
}
