/* ==========================================================================
11. SUCHE
========================================================================== */

.suche_erfolglos {
    width: 100%;
}

.suche_erfolglos p {
    color: #a5a5a5;
    margin-left: 10px;
    border-top: 1px solid #51cdb6;
    border-bottom: 1px solid #51cdb6;
    font-size: 32px;
    margin: 100px 0;
    padding: 50px 0;
}

span#suchKruemel {
    display: block;
    transition: all 300ms ease-in-out;
}

ul.sucheGross + a + div + .shop_mitte {
    border: 3px solid red;
    display: none;
}

ul.sucheGross {
    height: 0;
    transition: all 500ms ease-in-out;
}

ul.sucheGross:empty {
    margin: 0;
}

ul.sucheGross.ajaxLoaded {
    height: auto;
    transition: all 500ms ease-in-out;
}

ul.sucheGross li {
    opacity: 0;
}

ul.sucheGross.ajaxLoaded li {
    animation: flyin 600ms;
    animation-fill-mode: forwards;
}

@keyframes flyin {
    0% {
        transform: translateY(-30px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.sucheGross.ajaxLoaded li:nth-of-type(2) {
    animation-delay: 100ms;
}

.sucheGross.ajaxLoaded li:nth-of-type(3) {
    animation-delay: 300ms;
}

.sucheGross.ajaxLoaded li:nth-of-type(4) {
    animation-delay: 500ms;
}

.sucheGross.ajaxLoaded li:nth-of-type(5) {
    animation-delay: 700ms;
}

.sucheGross.ajaxLoaded li:nth-of-type(6) {
    animation-delay: 900ms;
}

.sucheGross.ajaxLoaded li:nth-of-type(7) {
    animation-delay: 1000ms;
}

.sucheGross.ajaxLoaded li:nth-of-type(8) {
    animation-delay: 1200ms;
}

a.button.ajax_se_suche {
    margin: 40px auto;
    display: block;
    max-width: 350px;
    text-align: center;
    background: $color-primary;
    color: #fff;
    border: none;
}

.list_se + .ajax_se_suche + #shopMain {
    display: none;
}
